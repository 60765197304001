<template>
  <div class="inventory-item-thumbnail">
    <img
      :src="inventoryItem.thumb"
      :alt="inventoryItem.name" />
    <p class="">{{ inventoryItem.name }}</p>
    <p class="additional-info additional-info-price">
      <strong>Pris: {{ getPriceFormatted(inventoryItem.price) }}</strong>
    </p>
    <p class="additional-info text-tiny">
      H:{{ inventoryItem.height }} x B:{{ inventoryItem.width === 'flex' ? `${inventoryItem.min_width} - ${inventoryItem.max_width}` :  inventoryItem.width}} x D:{{ inventoryItem.depth }}mm
    </p>
  </div>
</template>
<script>
import CustomDecimals from '@/utils/CustomDecimals.js';

export default {
  name: 'inventory-item-thumbnail',
  props: {
    inventoryItem: {
      type: Object,
      required: true
    }
  },
  methods: {
    getPriceFormatted (price) {
      return CustomDecimals.getPriceFormatted(price);
    }
  }
}
</script>
