import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    doors: {
      items: [], // items have following structure. {division_id:3, soft_close: false, fillings:[{filling_id:27},{filling_id:25}]}
      number: 0
    },
    doorsNumberObject: {
      number: 0,
      door_width: 0
    },
    inventory: [],
    maxHeight: 2750,
    maxWidth: 5000,
    minHeight: 2048,
    minWidth: 1000,
    isApplySameMaterial: false,
    price: {
      discount_id: null,
      discount_value: 0,
      door_price: 0,
      door_price_before_discount: 0,
      doors: [], // e.g. {: 0, normal_elements: 0, mirror_elements: 0, soft_close_price: 0, total_door_price: 0, divided: 2}
      doors_price: 0,
      gable_price: 0,
      impact_price: 0,
      inventory: {}, // e.g 2: 200.2
      inventory_price: 0,
      total_price: 0,
      total_price_before_discount: 0
    },
    wardrobe: {
      gable_filling_id: null,
      gable_id: null,
      height: 0,
      impact: false,
      impact_filling_id: null,
      is_walk_in_closet: false, // TODO: usunac
      left_end_adjustment: false, // TODO: czy nadal taki parametr?
      left_stroke_adjustment: false, // TODO: czy nadal taki parametr?
      position_id: null,
      right_end_adjustment: false, // TODO: czy nadal taki parametr?
      right_stroke_adjustment: false, // TODO: czy nadal taki parametr?
      soft_close: false, // TODO:z drzwi przeniesione tutaj
      width: 0
    }
  },
  mutations: {
    setDoors (state, doorsObject) {
      Object.assign(state.doors, doorsObject);
    },
    setDoorsNumber (state, doorsNumber) {
      Vue.set(state.doors, 'number', doorsNumber);

      // adjust doors item length if needed
      if (state.doors.items.length > doorsNumber) {
        state.doors.items.splice(doorsNumber);
      } else {
        while (state.doors.items.length < doorsNumber) {
          state.doors.items.push({ division_id: null, soft_close: state.hasSoftClose, fillings: [{ filling_id: null }] });
        }
      }
    },
    setDoorsNumberObject (state, doorsNumberObject) {
      Object.assign(state.doorsNumberObject, doorsNumberObject);
    },
    setDoorsItems (state, doorsItems) {
      Object.assign(state.doors.items, doorsItems);
    },
    setWardrobe (state, wardrobeObject) {
      Object.assign(state.wardrobe, wardrobeObject);
    },
    setWardrobeHeight (state, height) {
      Vue.set(state.wardrobe, 'height', height);
    },
    setWardrobeWidth (state, width) {
      Vue.set(state.wardrobe, 'width', width);
    },
    setWardrobePositionId (state, positionId) {
      Vue.set(state.wardrobe, 'position_id', positionId);
    },
    setWardrobeGableId (state, gableId) {
      Vue.set(state.wardrobe, 'gable_id', gableId);

      if (!gableId) {
        Vue.set(state.wardrobe, 'gable_filling_id', null);
      }
    },
    setWardrobeGableFillingId (state, gableFillingId) {
      Vue.set(state.wardrobe, 'gable_filling_id', gableFillingId);
    },
    setPrice (state, price) {
      Vue.set(state, 'price', price);
    },
    setWardrobeUseImpact (state, useImpact) {
      Vue.set(state.wardrobe, 'impact', useImpact);

      if (!useImpact) {
        Vue.set(state.wardrobe, 'impact_filling_id', null);
      }
    },
    setWardrobeImpactFillingId (state, impactFillingId) {
      Vue.set(state.wardrobe, 'impact_filling_id', impactFillingId);
    },
    setWardrobeRightEndAdjustment (state, rightEndAdjustment) {
      Vue.set(state.wardrobe, 'right_end_adjustment', rightEndAdjustment);
    },
    setWardrobeRightStrokeAdjustment (state, rightStrokeAdjustment) {
      Vue.set(state.wardrobe, 'right_stroke_adjustment', rightStrokeAdjustment);
    },
    setWardrobeLeftEndAdjustment (state, leftEndAdjustment) {
      Vue.set(state.wardrobe, 'left_end_adjustment', leftEndAdjustment);
    },
    setWardrobeLeftStrokeAdjustment (state, leftStrokeAdjustment) {
      Vue.set(state.wardrobe, 'left_stroke_adjustment', leftStrokeAdjustment);
    },
    setWardrobeSoftClose (state, hasSoftClose) {
      Vue.set(state.wardrobe, 'soft_close', hasSoftClose);

      for (let i = 0; i < state.doors.items.length; i++) {
        state.doors.items[i].soft_close = hasSoftClose;
      }
    },
    setInventory (state, inventory) {
      Vue.set(state, 'inventory', inventory);
    },
    addInventoryItem (state, itemToAdd) {
      state.inventory.push(itemToAdd);
    },
    updateInventoryItemProp (state, { stageID, propName, newPropValue }) {
      let itemToUpdate = state.inventory.find(item => item.stageID === stageID);

      if (!itemToUpdate) {
        console.log('item not found ', stageID);
        return;
      }

      itemToUpdate[propName] = newPropValue;
    },
    addInventoryAttachedItem (state, { stageID, childID }) {
      let itemToUpdate = state.inventory.find(item => item.stageID === stageID);

      if (!itemToUpdate) {
        console.log('item not found ', stageID);
        return;
      }

      if (itemToUpdate.attachedItems.indexOf(childID) > -1) {
        return;
      }

      itemToUpdate.attachedItems.push(childID);
    },
    removeInventoryAttachedItem (state, { stageID, childID }) {
      let itemToUpdate = state.inventory.find(item => item.stageID === stageID);

      if (!itemToUpdate) {
        console.log('item not found ', stageID);
        return;
      }

      let childItemIndex = itemToUpdate.attachedItems.indexOf(childID);
      itemToUpdate.attachedItems.splice(childItemIndex, 1);
    },
    removeInventoryItem (state, stageID) {
      let itemToRemoveIndex = state.inventory.findIndex(item => item.stageID === stageID);
      if (itemToRemoveIndex > -1) {
        state.inventory.splice(itemToRemoveIndex, 1);

        let parent = state.inventory.find(item => item.attachedItems && item.attachedItems.indexOf(stageID) > -1);

        if (parent) {
          let itemIndex = parent.attachedItems.indexOf(stageID)
          parent.attachedItems.splice(itemIndex, 1);
        }
      }
    },
    resetInventory (state) {
      state.inventory = [];
    },
    resetPrice (state) {
      let priceKeys = Object.keys(state.price);

      priceKeys.forEach(item => {
        if (item === 'doors') {
          state.price.doors = [];
        } else if (item === 'inventory') {
          state.price.inventory = {};
        } else {
          state.price[item] = 0;
        }
      })
    },
    setIsApplySameMaterial (state, isApplySameMaterial) {
      Vue.set(state, 'isApplySameMaterial', isApplySameMaterial);
    },
  },
  getters: {
    doors: (state) => state.doors,
    doorsNumber: (state) => state.doors.number,
    doorsNumberObject: (state) => state.doorsNumberObject,
    doorsItems: (state) => state.doors.items,
    inventory: (state) => state.inventory,
    inventoryIDs: (state) => state.inventory.map(item => ({ inventory_id: item.id })),
    wardrobe: (state) => state.wardrobe,
    wardrobeHeight: (state) => state.wardrobe.height,
    wardrobeWidth: (state) => state.wardrobe.width,
    wardrobeGableId: (state) => state.wardrobe.gable_id,
    wardrobeGableFillingId: (state) => state.wardrobe.gable_filling_id,
    wardrobeImpact: (state) => state.wardrobe.impact,
    wardrobeImpactFillingId: (state) => state.wardrobe.impact_filling_id,
    wardrobePositionId: (state) => state.wardrobe.position_id,
    wardrobeSoftClose: (state) => state.wardrobe.soft_close,
    maxHeight: (state) => state.maxHeight,
    maxWidth: (state) => state.maxWidth,
    minHeight: (state) => state.minHeight,
    minWidth: (state) => state.minWidth,
    price: (state) => state.price,
    wardrobeRightEndAdjustment: (state) => state.wardrobe.right_end_adjustment,
    wardrobeLeftEndAdjustment: (state) => state.wardrobe.left_end_adjustment,
    isApplySameMaterial: (state) => state.isApplySameMaterial
  }
};
