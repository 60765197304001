<template>
  <div class="wardrobe-preview">
    <div class="alert alert-info">
      <p class="wrapper text-bold">Vi beregner 10mm luft fra husvæggene.</p>
    </div>

    <div
      :class="{
        'wardrobe-box': isRulerVisible,
        'is-walls-hidden': !isBoxVisible,
        'is-material-view': isMaterialView
      }"
      v-bind:style="true ? { 'aspect-ratio': (widthForDisplay / heightForDisplay) } : {}">
      <template v-if="isRulerVisible">
        <span
          v-if="heightForDisplay"
          class="ruler ruler-height">
          {{ heightForDisplay }}mm
        </span>
        <span
          v-if="widthForDisplay"
          class="ruler ruler-width">
          {{ widthForDisplay }}mm
        </span>
      </template>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'wardrobe-preview',
  props: {
    currentWardrobeHeight: {
      default: 0,
      typer: Number
    },
    currentWardrobeWidth: {
      default: 0,
      typer: Number
    }
  },
  computed: {
    ...mapGetters('creator', [
      'wardrobeHeight',
      'wardrobeWidth',
      'maxWidth',
      'maxHeight',
      'minHeight',
      'minWidth'
    ]),
    isBoxVisible () {
      return this.viewsWithBox.includes(this.$route.name);
    },
    isRulerVisible () {
      return this.$route.name !== 'wardrobeInventory';
    },
    isMaterialView () {
      return this.$route.name === 'wardrobeDoorsMaterial';
    },
    widthForDisplay () {
      return Math.min((this.currentWardrobeWidth || this.wardrobeWidth || this.minWidth), this.maxWidth)
    },
    heightForDisplay () {
      return Math.min((this.currentWardrobeHeight || this.wardrobeHeight || this.minHeight), this.maxHeight);
    }
  },
  data () {
    return {
      viewsWithBox: ['wardrobeDimensions', 'wardrobePosition']
    }
  },
}
</script>
