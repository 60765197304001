<template>
  <div class="splashscreen">
    <div class="loading-spinner-wrapper">
      <div class="loading-spinner pb-3 pt-3">
        <span class="loading-spinner-content">
          Indlæsning af data&hellip;
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'splashscreen',
  mounted () {
    setTimeout(() => {
      this.$router.push({ name: 'startScreen' });
    }, 1000);
  }
}
</script>
