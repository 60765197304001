var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-content creator-view"},[(!_vm.loaded)?_c('div',{staticClass:"loading-overlay"},[_vm._m(0)]):_vm._e(),_c('wardrobe-preview'),_c('right-sidebar',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',[_vm._v("Placering")])]},proxy:true},{key:"content",fn:function(){return [_c('validation-observer',{ref:"position-form",attrs:{"tag":"form","id":"positionForm"}},[_c('div',{staticClass:"form-group"},[_c('validation-provider',{staticClass:"full-width",attrs:{"rules":"required","name":"wardrobe-position-with-gable","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"wardrobe-positions"},_vm._l((_vm.positionsWithGable),function(position,index){return _c('c-form-radio',{key:index,class:{ 'relative': true, 'is-invalid': errors.length },attrs:{"withImage":true,"value":position.id},model:{value:(_vm.data.wardrobePosition),callback:function ($$v) {_vm.$set(_vm.data, "wardrobePosition", $$v)},expression:"data.wardrobePosition"}},[_c('tooltip',{attrs:{"container-element-class":'sidebar-content',"text":_vm.getPositionDescription(position.id)}}),_c('img',{staticClass:"position-image",attrs:{"src":position.image,"alt":'Placering - ' + position.name}}),_c('span',{staticClass:"c-form-radio-label"},[_vm._v(_vm._s(position.name))])],1)}),1)]}}])})],1),_c('div',{staticClass:"form-group modificate"},[_c('validation-provider',{staticClass:"flex flex-column",attrs:{"rules":_vm.isGableEnabled ? 'required' : '',"name":"wardrobe-gable","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"wardrobe-positions new-style-radio"},[_c('span',[_vm._v("Dybde:")]),_vm._l((_vm.optionsGable),function(gable,index){return _c('c-form-radio',{key:index,class:{ 'is-invalid': errors.length },attrs:{"value":gable.id,"disabled":!_vm.isGableEnabled},model:{value:(_vm.data.gableID),callback:function ($$v) {_vm.$set(_vm.data, "gableID", $$v)},expression:"data.gableID"}},[_c('span',{staticClass:"c-form-radio-label"},[_vm._v(_vm._s(gable.name))])])})],2),_c('div',{staticClass:"invalid-feedback text-center"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"form-group mt-1"},[_c('validation-provider',{staticClass:"full-width centered",attrs:{"rules":"required","name":"wardrobe-position-no-gable","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"wardrobe-positions"},_vm._l((_vm.positionsWithoutGable),function(position,index){return _c('c-form-radio',{key:index,class:{ 'relative': true, 'is-invalid': errors.length },attrs:{"withImage":true,"value":position.id},model:{value:(_vm.data.wardrobePosition),callback:function ($$v) {_vm.$set(_vm.data, "wardrobePosition", $$v)},expression:"data.wardrobePosition"}},[_c('tooltip',{attrs:{"container-element-class":'sidebar-content',"text":_vm.getPositionDescription(position.id)}}),_c('img',{staticClass:"position-image",attrs:{"src":position.image,"alt":'Placering - ' + position.name}}),_c('span',{staticClass:"c-form-radio-label"},[_vm._v(_vm._s(position.name))])],1)}),1)]}}])})],1),_c('div',{staticClass:"showcase-attention mt-5"},[_c('strong',[_vm._v("Har du ujævne vægge?")]),_c('p',{staticClass:"mb-1"},[_vm._v("Tilføj en anslag liste til din ordre. Et anslag monteres til væggen for rette skævheder op.")]),_c('div',{staticClass:"form-group"},[_c('validation-provider',{staticClass:"flex flex-column",attrs:{"rules":_vm.isGableEnabled ? 'required' : '',"name":"has-uneven-walls","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"wardrobe-positions flex flex-wrap  new-style-radio"},[_c('c-form-radio',{class:{ 'is-invalid': errors.length },attrs:{"value":true,"disabled":!_vm.isImpactEnabled},model:{value:(_vm.data.useImpact),callback:function ($$v) {_vm.$set(_vm.data, "useImpact", $$v)},expression:"data.useImpact"}},[_c('span',{staticClass:"c-form-radio-label bold"},[_vm._v("Tilføj anslag")])]),_c('c-form-radio',{class:{ 'is-invalid': errors.length },attrs:{"value":false,"disabled":!_vm.isImpactEnabled},model:{value:(_vm.data.useImpact),callback:function ($$v) {_vm.$set(_vm.data, "useImpact", $$v)},expression:"data.useImpact"}},[_c('span',{staticClass:"c-form-radio-label bold"},[_vm._v("Nej tak til anslag")])])],1)]}}])})],1)])])]},proxy:true},{key:"footer",fn:function(){return [_c('navigation-buttons',{attrs:{"form-id":'positionForm'},on:{"nextBtnClicked":_vm.validateForm,"backBtnClicked":_vm.goBack}})]},proxy:true}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loader"},[_c('span',[_vm._v("Indlæsning af data…")])])}]

export { render, staticRenderFns }