<template>
  <div class="showcase-flex">
    <div class="showcase">
      <img
        class=""
        :src="require('@/assets/images/measure-height.jpg')"
        alt="Hvordan man måler højde" />
      <strong class="showcase-title">Højde</strong>
      <p>Højden måles på tre steder - i venstre side, i midten og i højre side. Det er den lavaste højde, som bestemmer lågehøjden.</p>
    </div>

    <div class="showcase">
      <img
        class=""
        :src="require('@/assets/images/measure-width.jpg')"
        alt="Hvordan man måler bredde" />
      <strong class="showcase-title">Bredde</strong>
      <p>Mål bredden både ved gulvet og loftet. Det giver et billede af, om væggene er skæve. Det er det mindste af målene, der bestemmer lågerbredden.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'measurement-instructions'
}
</script>
