<template>
  <ul class="creator-progress mx-auto">
    <li
      v-for="(phase, phaseIndex) in visibleCreatorPhases"
      :key="'phase-' + phaseIndex">
      <router-link
        :title="phase.label"
        :to="{ name: phase.steps[0] }"
        :class="{
          'c-btn c-btn-icon': true,
          'is-active': getIsPhaseActive(phase)
        }"
        :disabled="getIsPhaseDisabled(phaseIndex)">
        <span>
          <div class="img-wrapper">
            <img
              class=""
              :src="phase.icon" />
          </div>
          <span class="text">{{ phase.label }}</span>
        </span>
      </router-link>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'creator-progress',
  computed: {
    ...mapGetters('application', [
      'hasWardrobe',
      'hasDoor',
      'hasInventory'
    ]),
    creatorTypeSteps () {
      let creatorSteps = ['creatorType', 'wardrobeDimensions'];
      if (this.hasWardrobe) {
        creatorSteps.push('wardrobePosition');
      }
      if (this.hasDoor) {
        creatorSteps.push('wardrobeDoorsNumber');
        creatorSteps.push('wardrobeDoorsDivision');
        creatorSteps.push('wardrobeDoorsMaterial');
      }
      if (this.hasInventory) {
        creatorSteps.push('wardrobeInventory');
      }

      return creatorSteps;
    },
    creatorPhases () {
      return [
        {
          icon: require('@/assets/images/icons/wardrobe-icon.webp'),
          isVisible: true,
          label: 'Mål og placering',
          steps: ['wardrobeDimensions', 'wardrobePosition'].filter(item => this.creatorTypeSteps.includes(item))
        },
        {
          icon: require('@/assets/images/icons/wardrobe-icon.webp'),
          isVisible: this.hasDoor,
          label: 'Skydelåge design',
          steps: ['wardrobeDoorsNumber', 'wardrobeDoorsDivision', 'wardrobeDoorsMaterial'].filter(item => this.creatorTypeSteps.includes(item))
        },
        {
          icon: require('@/assets/images/icons/wardrobe-icon.webp'),
          isVisible: this.hasInventory,
          label: 'Indretning',
          steps: ['wardrobeInventory'].filter(item => this.creatorTypeSteps.includes(item))
        },
        {
          icon: require('@/assets/images/icons/wardrobe-icon.webp'),
          isVisible: true,
          label: 'Bekræftelse',
          steps: ['placeOrder']
        }
      ]
    },
    visibleCreatorPhases () {
      return this.creatorPhases.filter(item => item.isVisible);
    }
  },
  methods: {
    getIsPhaseDisabled (phaseIndex) {
      // step is disabled if previous step is not yet completed
      if (phaseIndex === 0) {
        return false;
      }

      let prevPhase = this.visibleCreatorPhases[phaseIndex - 1];
      let prevPhaseLastStepIndex = prevPhase.steps.length - 1;
      return !this.$store.getters['application/getIsStepCompleted'](prevPhase.steps[prevPhaseLastStepIndex]);
    },
    getIsPhaseActive (phase) {
      if (this.$route.query && this.$route.query.orderSummary) {
        return phase.steps.includes('placeOrder');
      }

      return phase.steps.includes(this.$route.name);
    }
  }
}
</script>
