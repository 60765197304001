<template>
  <section class="summary">
    <h3
      v-if="!isSummaryPopupVisible"
      class="mb-1">Oversigt
    </h3>
    <div class="summary-content">
      <p class="my-0">Mål:</p>
      <div class="flex flex-wrap mb-2">
        <span class="mr-3">Bredde: <strong>{{ wardrobeWidth }}mm</strong></span>
        <span class="mr-3">Højde: <strong>{{ wardrobeHeight }}mm</strong></span>
        <p
          v-if="gableObject"
          class="flex my-0 full-width">
          <span class="mr-2">Gavl: <strong>{{ gableObject.name }}</strong></span>
          <span class="ml-auto">{{ price && price.gable_price ? getPriceFormatted(price.gable_price) : '-' }}</span>
        </p>
        <span
          v-if="wardrobePositionObject"
          class="mr-3 full-width">
          Placering: <strong>{{ wardrobePositionObject.name }}</strong>
        </span>
        <p
          v-if="wardrobeImpact"
          class="flex my-0 full-width">
          <span class="mr-2">Anslag: <strong>{{ impactTxt }}</strong></span>
          <!-- TODO: jak to liczyć? 2stk? -->
          <span class="ml-auto">{{ price && price.impact_price ? getPriceFormatted(price.impact_price) : '-' }}</span>
        </p>
        <p
          v-if="gableObject"
          class="flex my-0 full-width">
          <span class="mr-2">Gavl farve: <strong>{{ gableFillingObject ? gableFillingObject.name : '-' }}</strong></span>
        </p>
        <p
          v-if="wardrobeImpact"
          class="flex my-0 full-width">
          <span class="mr-2">Anslag farve: <strong>{{ impactFillingObject ? impactFillingObject.name : '-' }}</strong></span>
        </p>

        <!-- TODO: czy dla anslag tez kolor w końcu? -->
      </div>

      <template v-if="hasDoor">
        <div
          v-for="(door, doorIndex) in doorsItems"
          :key="'door-' + doorIndex"
          class="mb-1">
          <p class="flex my-0">
            <strong class="mr-2">Skydelåger {{ doorIndex  + 1}}</strong>
            <span class="ml-auto">{{ getPriceFormatted(price.doors[doorIndex].total_door_price) }}</span>
          </p>
          <p class="my-0">B: {{ doorsNumberObject.door_width }}mm; H: {{wardrobeHeight}}mm</p>
          <p
            v-for="(filling, fillingIndex) in door.fillings"
            :key="'door-' + doorIndex + '-filling-' + fillingIndex"
            class="my-0">
            {{ getDoorPartColorLabel(fillingIndex, door.fillings.length, filling.filling_id) }}
          </p>
        </div>
      </template>

      <template v-if="hasInventory">
        <strong>Indretning</strong>
        <div
          v-for="(inventoryItem, inventoryItemIndex) in inventoryForSummary"
          :key="'inventory-' + inventoryItemIndex">
          <p class="flex my-0">
            <span>{{ inventoryItem.name }} (x{{ inventoryItem.quantity }})</span>
            <span class="ml-auto">{{ getPriceFormatted(inventoryItem.price) }}</span>
          </p>
        </div>
      </template>
    </div>
    <!-- <div class="flex mt-auto summary-total">
      <strong class="mr-2">Total pris:</strong>
      <div class="ml-auto">
        <span
          v-if="price.total_price_before_discount"
          class="total-price-discount mr-2">
          {{ getPriceFormatted(price.total_price_before_discount) }}
        </span>
        <span class="total-price">
          {{ getPriceFormatted(price.total_price) }}
        </span>
      </div>
    </div> -->
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import CommonGetters from '@/utils/CommonGetters.js';
import CustomDecimals from '@/utils/CustomDecimals.js';

export default {
  name: 'order-summary',
  props: {
  },
  computed: {
    ...mapGetters('creator', [
      'wardrobeHeight',
      'wardrobeWidth',
      'wardrobePositionId',
      'doorsNumber',
      'doorsNumberObject',
      'wardrobeGableId',
      'wardrobeImpact',
      'wardrobeGableFillingId',
      'wardrobeImpactFillingId',
      'doorsItems',
      'inventory',
      'price',
      'wardrobeRightEndAdjustment',
      'wardrobeLeftEndAdjustment'
    ]),
    ...mapGetters('apiData', [
      'optionsWardrobePositions',
      'optionsGable',
      'optionsGableFillings',
      'optionsImpactFillings',
      'optionsDoorFillings',
      'optionsInventoryCategories'
    ]),
    ...mapGetters('application', [
      'hasDoor',
      'hasInventory',
      'isSummaryPopupVisible'
    ]),
    wardrobePositionObject () {
      if (!this.wardrobePositionId) {
        return null;
      }
      return this.optionsWardrobePositions.find(item => item.id === this.wardrobePositionId);
    },
    gableObject () {
      if (!this.wardrobeGableId) {
        return null;
      }
      return this.optionsGable.find(item => item.id === this.wardrobeGableId);
    },
    gableFillingObject () {
      return this.optionsGableFillings.find(item => item.id === this.wardrobeGableFillingId);
    },
    impactTxt () {
      if (!this.wardrobeImpact) {
        return '';
      }

      let count = 0;

      if (this.wardrobeRightEndAdjustment) {
        count += 1;
      }

      if (this.wardrobeLeftEndAdjustment) {
        count += 1;
      }
      return count + ' stk.';
    },
    impactFillingObject () {
      return this.optionsImpactFillings.find(item => item.id === this.wardrobeImpactFillingId);
    },
    inventoryForSummary () {
      if (!this.inventory.length) {
        return [];
      }

      let outputArray = [];

      for (let i = 0; i < this.inventory.length; i++) {
        let currentItem = this.inventory[i];
        let isGableTop = currentItem.isGableTop;
        let existingItemInArray = outputArray.find(item => item.id === currentItem.id && item.width === currentItem.width);
        let detailsAdditional = '';

        if (existingItemInArray) {
          existingItemInArray.quantity += 1;
          existingItemInArray.price += (this.price.inventory[currentItem.id] || 0);
          if (isGableTop) {
            existingItemInArray.gableTopsQuantity += 1;
            if (existingItemInArray.gableTopsQuantity > 1) {
              existingItemInArray.additionalInfo = `Inkludere ${existingItemInArray.gableTopsQuantity} top hylder`;
            } else {
              existingItemInArray.additionalInfo = `Inkludere ${existingItemInArray.gableTopsQuantity} top hylde`;
            }
          }
        } else {
          if (isGableTop) {
            detailsAdditional = 'Inkludere 1 top hylde';
          }
          outputArray.push({
            additionalInfo: detailsAdditional,
            details: `B: ${currentItem.width.toFixed(0)}mm; H: ${currentItem.height.toFixed(0)}mm; D: ${currentItem.depth.toFixed(0)}mm`,
            name: currentItem.name,
            height: currentItem.height,
            id: currentItem.id,
            price: this.price.inventory[currentItem.id] || 0,
            productCode: currentItem.product_code,
            quantity: 1,
            width: currentItem.width,
            gableTopsQuantity: +isGableTop
          })
        }
      }

      outputArray = outputArray.sort((CustomDecimals.sortAscBy('id')));
      return outputArray;
    },
  },
  data() {
    return {
    };
  },
  mounted () {
  },
  methods: {
    getFillingName (fillingID, surfaceType = 'door') {
      if (!fillingID) {
        return '-';
      }

      let fillingFound = null;
      if (surfaceType === 'door') {
        fillingFound = this.optionsDoorFillings.find(item => item.id === fillingID);
      } else if (surfaceType === 'gable') {
        fillingFound = this.optionsGableFillings.find(item => item.id === fillingID);
      } else if (surfaceType === 'impact') {
        fillingFound = this.optionsImpactFillings.find(item => item.id === fillingID);
      } else {
        console.error('invalid surface type ', surfaceType);
        return '-';
      }

      if (fillingFound && fillingFound.name) {
        return fillingFound.name;
      }
      return '-';
    },
    getDoorPartColorLabel (doorPartIndex, allPartsCount, fillingId) {
      let output = CommonGetters.getDoorPartLabel(doorPartIndex, allPartsCount);
      if (output && output.length) {
        output += 'farve: ';
      } else {
        output += 'Farve: ';
      }

      output += this.getFillingName(fillingId);
      return output;
    },
    getPriceFormatted (price) {
      return CustomDecimals.getPriceFormatted(price);
    }
  }
}
</script>
