<template>
  <div>
    <div
      v-if="!allDataLoaded"
      class="loading-overlay">
      <div class="loader">
        <span>Indlæsning af data&hellip;</span>
      </div>
    </div>
    <transition name="fade">

      <component
        :is="layout"
        class="page-wrapper"
        :is-load-error="loadError">
        <router-view
          :key="$route.fullPath"
          :is-load-error="loadError" />
      </component>
    </transition>
    <cookie-banner ref="cookie-banner" />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import FormUtils from '@/utils/FormUtils.js';
import { autoSaveProject } from '@/utils/AutoSave.js';
import debounce from 'lodash.debounce';
import CookieBanner from '@/components/popups/CookieBanner.vue';

export default {
  components: {
    'cookie-banner': CookieBanner
  },
  data () {
    return {
      initialDataLoaded: false,
      isAutoSaveActivated: false,
      isFetched: false,
      loadError: false,
      priceLoadError: false
    }
  },
  computed: {
    ...mapGetters('creator', [
      'wardrobe',
      'inventoryIDs',
      'doors'
    ]),
    allDataLoaded () {
      return this.initialDataLoaded && this.isFetched;
    },
    layout () {
      if (!this.$route.meta) {
        return 'default-layout';
      }

      return `${this.$route.meta.layout || 'default'}-layout`;
    }
  },
  watch: {
    '$route.fullPath': function(newVal, oldVal) {
      if (oldVal.endsWith('start-screen') || oldVal.endsWith('loading')) {
        return;
      }
      if (this.isAutoSaveActivated) {
        let stateToSave = {
          doors: this.$store.getters['creator/doors'],
          inventory: this.$store.getters['creator/inventory'],
          wardrobe: this.$store.getters['creator/wardrobe'],
          stepsCompletion: this.$store.getters['application/stepsState'],
          hasDoorOnly: this.$store.getters['application/hasDoorOnly'],
          hasInventoryOnly: this.$store.getters['application/hasInventoryOnly'],
          hasFullWardrobe: this.$store.getters['application/hasFullWardrobe']
        }
        autoSaveProject(stateToSave);
      }
    }
  },
  mounted () {
    this.$bus.$on('update-wardrobe-price', this.debouncedUpdateWardrobePrice);

    FormUtils.loadData(this, {
      method: 'get',
      endpoint: '/api/init',
      loadedKey: 'initialDataLoaded',
      errorKey: 'loadError',
      noPagination: true,
      successAction: (response) => {
        this.$store.commit('apiData/setApiData', response);

        this.isFetched = true;
      }
    });


    Vue.nextTick(() => {
      this.isAutoSaveActivated = true;
    })
  },
  methods: {
    debouncedUpdateWardrobePrice: debounce(function () {
      // TODO: liczenie ceny nie działa jak puste doors.items. Sprawdzić jak jest kiedy samo inventory
      console.log('debouncedUpdateWardrobePrice');
      Vue.nextTick(() => {
        FormUtils.loadData(this, {
          endpoint: '/api/prices/calculate',
          method: 'post',
          noPagination: true,
          errorKey: 'priceLoadError',
          params: {
            wardrobe: this.wardrobe,
            doors: this.doors,
            inventory: this.inventoryIDs
          },
          successAction: (response) => {
            this.$store.commit('creator/setPrice', response);
          },
          failAction: () => {
            this.$store.commit('creator/resetPrice');
          }
        });
      });
    }, 250),
  },
  beforeDestroy () {
    this.$bus.$off('update-wardrobe-price', this.debouncedUpdateWardrobePrice);
  }
};
</script>
