<template>
  <div class="content-wrapper">
    <div class="view-content flex">
        <div class="main-content start-screen py-3 my-auto">
          <validation-observer
            v-if="loaded"
            ref="type-selection-form"
            tag="form"
            class="type-selection-form full-width">

            <div class="form-group m-0 full-width">
              <validation-provider
                rules="required"
                name="wardrobe-position"
                v-slot="{ errors }"
                tag="div"
                class="options-wrapper">
                <c-form-radio
                  :radio-tile="true"
                  value="hasDoorOnly"
                  v-model="data.creatorType"
                  :withImage="true"
                  :class="{ 'is-invalid': errors.length, 'm-3 m-md-2': true }">
                  <img
                    :src="require('@/assets/images/doors-only-thumb.jpg')"
                    alt="Kun skydelåger" />
                  <span class="c-form-radio-label">Kun skydelåger</span>
                </c-form-radio>
                <c-form-radio
                  :radio-tile="true"
                  value="hasFullWardrobe"
                  v-model="data.creatorType"
                  :withImage="true"
                  :class="{ 'is-invalid': errors.length, 'm-3 m-md-2': true }">
                  <img
                    :src="require('@/assets/images/full-wardrobe-thumb.jpg')"
                    alt="Garderobe med skydelåger" />
                  <span class="c-form-radio-label">Garderobe med skydelåger</span>
                </c-form-radio>
                <c-form-radio
                  :radio-tile="true"
                  value="hasInventoryOnly"
                  v-model="data.creatorType"
                  :withImage="true"
                  :class="{ 'is-invalid': errors.length, 'm-3 m-md-2': true }">
                  <img
                    :src="require('@/assets/images/inventory-only-thumb.jpg')"
                    alt="Kun inventar" />
                  <span class="c-form-radio-label">Kun inventar</span>
                </c-form-radio>
              </validation-provider>
            </div>
          </validation-observer>
      </div>
    </div>
    <div class="buttons-bar">
      <c-button
        theme="primary"
        class="ml-auto"
        @click.prevent="validateForm">
        NÆSTE
      </c-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'start-screen',
  data () {
    return {
      data: {
        creatorType: ''
      },
      loaded: false
    }
  },
  mounted () {
    this.loadDataFromStore();
  },
  methods: {
    loadDataFromStore () {
      Vue.set(this, 'loaded', false);

      if (this.$store.getters['application/hasDoorOnly']) {
        this.data.creatorType = 'hasDoorOnly';
      } else if (this.$store.getters['application/hasInventoryOnly']) {
        this.data.creatorType = 'hasInventoryOnly';
      } else if (this.$store.getters['application/hasFullWardrobe']) {
        this.data.creatorType = 'hasFullWardrobe';
      }

      Vue.nextTick(() => {
        this.loaded = true;
      });
    },
    validateForm () {
      FormUtils.validate(this.$refs['type-selection-form'], this.confirmSubmitForm);
    },
    confirmSubmitForm () {
      if (this.$store.getters['application/getIsStepCompleted']('creatorType') && this.areChangesMade()) {
        Vue.swal({
          title: 'Note',
          text: 'Ændringer i dette trin, kan have indflydelse på senere trin.',
          icon: 'warning',
          confirmButtonText: 'OK',
        }).then(() => {
          this.submitForm();
        });
      } else {
        this.submitForm();
      }
    },
    submitForm () {
      let isSettingChanged = this.areChangesMade();
      if (isSettingChanged) {
        this.$store.commit('creator/resetPrice');
        this.$store.commit('creator/resetInventory');
        this.$store.commit('creator/setDoorsNumber', 0);
      }

      if (this.data.creatorType === 'hasDoorOnly') {
        this.$store.commit('application/setHasDoorsOnly');
      } else if (this.data.creatorType === 'hasInventoryOnly') {
        this.$store.commit('application/setHasInventoryOnly');
      } else {
        this.$store.commit('application/setHasFullWardrobe');
      }

      this.$store.commit('application/setStepCompletion', { stepName: 'creatorType', stepCompleted: true });

      // TODO: w odpowiednim przypadku zrobić czyszczenie store

      Vue.nextTick(() => {
        this.$router.push({ name: 'wardrobeDimensions' });
      });
    },
    areChangesMade () {
      return (this.$store.getters['application/hasDoorOnly'] !== (this.data.creatorType === 'hasDoorOnly')) ||
        (this.$store.getters['application/hasInventoryOnly'] !== (this.data.creatorType === 'hasInventoryOnly')) ||
        (this.$store.getters['application/hasFullWardrobe'] !== (this.data.creatorType === 'hasFullWardrobe'))
    }
  }
};
</script>

<style lang="scss" scoped>
.type-selection-form {
  .options-wrapper {
    align-items: stretch;
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    .c-form-radio {
      width: 275px;
      padding: 0;
      height: unset;
      img {
        max-width: 100%;
        object-fit: contain;
        // margin-bottom: 20px;
      }

      .c-form-radio-label {
        font-size: 20px;
        margin: 33px 10px 10px 10px;
      }
    }
  }
}
.wrapper-narrow {
  max-width: 100%!important;
  padding: 0 !important;
}
.buttons-bar {
  height: auto;
  .c-btn {
    text-align: center;
    justify-content: center;
    max-width: 230px;
    width: 100%;
  }
}
</style>
