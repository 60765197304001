<template>
  <div class="details-container">
    <!-- <details
      v-for="(category, categoryIndex) in optionsInventoryCategories"
      :key="categoryIndex"
      class="details">
      <summary class="details-item-title">{{ category.name }}</summary> -->
      <div class="details-item-content">
        <c-button
          v-for="(item, index) in optionsInventoryPresets"
          :disabled="!getIsPresetInsertable(item)"
          :key="index"
          class="inventory-category"
          theme="tile"
          @click.prevent="addPreset(item)">

          <div class="img-wrapper">
            <!-- TODO: adjust when data will be served form API -->
            <img
              class="inventory-category-thumbnail"
              :src="require('@/assets/images/preset-140-1.png')" />
          </div>
          <p class="inventory-category-name">
            {{ item.name }}
          </p>
          <p class="additional-info additional-info-price">
            <strong>Pris: {{ getPriceFormatted(item.price) }}</strong>
          </p>
          <!-- <p class="additional-info text-tiny">
            H:{{ item.height }} x B:{{ item.width === 'flex' ? `${item.min_width} - ${item.max_width}` :  item.width}} x D:{{ item.depth }}mm
          </p> -->
          <p
            v-if="!getIsPresetInsertable(item)"
            class="inventory-message">
            Der er ikke plads nok til dette sæt.
          </p>
        </c-button>
      </div>
    <!-- </details> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CustomDecimals from '@/utils/CustomDecimals.js';

export default {
  name: 'presets-list',
  props: {
    biggestSpace: {
      default: 0,
      type: Number
    }
  },
  computed: {
    ...mapGetters('apiData', [
      'optionsInventoryPresets'
    ]),
    ...mapGetters('creator', [
      'wardrobeWidth'
    ]),
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    addPreset (preset) {
      this.$emit('add-preset-to-wardrobe', preset);
    },
    getIsPresetInsertable (preset) {
      return preset.width <= this.biggestSpace;
    },
    getPriceFormatted (price) {
      return CustomDecimals.getPriceFormatted(price);
    }
  }
}
</script>
