<template>
  <div class="view-content creator-view">
    <div
      v-if="!loaded"
      class="loading-overlay">
      <div class="loader">
        <span>Indlæsning af data&hellip;</span>
      </div>
    </div>

    <wardrobe-preview
      :current-wardrobe-height="data.height"
      :current-wardrobe-width="data.width" />

    <right-sidebar>
      <template v-slot:header>
        <h2>Angiv mål</h2>
      </template>

      <template v-slot:content>
        <validation-observer
          ref="dimensions-form"
          tag="form"
          id="dimensionsForm">

          <div class="form-field">
            <validation-provider
              :rules="`required|min_value:${minWidth}|max_value:${maxWidth}`"
              name="wardrobe-width"
              v-slot="{ errors }">
              <label
                for="wardrobe-width"
                :class="{
                  'form-input': true,
                  'is-invalid': errors.length
                }">
                <span class="form-input-name">Bredde</span>
                <c-input
                  type="number"
                  v-model="data.width"
                  :state="errors.length ? 'invalid' : null"/>
                <span class="form-input-appendix">mm</span>
              </label>
              <div class="field-instructions">
                OBS! Bredden skal være imellem {{ minWidth }}mm og {{ maxWidth }}mm.
              </div>
            </validation-provider>
          </div>

          <div class="form-field">
            <validation-provider
              :rules="`required|min_value:${minHeight}|max_value:${maxHeight}`"
              name="wardrobe-height"
              v-slot="{ errors }">
              <label
                for="wardrobe-height"
                :class="{
                  'form-input': true,
                  'is-invalid': errors.length
                }">
                <span class="form-input-name">Højde</span>
                <c-input
                  type="number"
                  v-model="data.height"
                  :state="errors.length ? 'invalid' : null"/>
                <span class="form-input-appendix">mm</span>
              </label>

              <div class="field-instructions">
                OBS! Højden skal være imellem {{ minHeight }}mm og {{ maxHeight }}mm.
              </div>
            </validation-provider>
          </div>
        </validation-observer>

        <div class="text-center mt-3 showcase-attention">
          <strong>Se vores opmåling guide her</strong>
          <p>Husk at det er vigtigt at måle højde i 3 forskellige placeringer i forhold til loftet. Man skal angive det laveste mål i programmet for at få gavle tilpasset. Husk ved at vælge “væg til væg” placering, bregner vi 10 mm luft mellem væggene.</p>
        </div>

        <div
          class="showcase-data"
          style="cursor: pointer;"
          @click.prevent="showMeasurementModal">
          <strong class="showcase-data-title text-center">Hvordan måler jeg rigtigt op?</strong>
          <measurement-instructions/>
        </div>
      </template>

      <template v-slot:footer>
        <navigation-buttons
          :form-id="'dimensionsForm'"
          @nextBtnClicked="validateForm"
          @backBtnClicked="goBack" />
      </template>
    </right-sidebar>

    <transition name="fade">
      <c-modal
        v-if="isMeasurementModalVisible"
        ref="measurement-modal"
        :class="{ 'is-visible': isMeasurementModalVisible, 'is-narrow is-centered': true }"
        :isPersistent="false"
        :is-visible="isMeasurementModalVisible"
        @close="handleMeasurementModalClose">
        <c-modal-header class="text-center">
          Hvordan måler jeg rigtigt op?
        </c-modal-header>
        <c-modal-body>
          <measurement-instructions/>
        </c-modal-body>
      </c-modal>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import FormUtils from '@/utils/FormUtils.js';
import { loadAutoSavedProject } from '@/utils/AutoSave.js';
import MeasurementInstructions from '@/components/forms/dimensions/MeasurementInstructions.vue';

export default {
  name: 'wardrobe-dimensions',
  components: {
    'measurement-instructions': MeasurementInstructions
  },
  computed: {
    ...mapGetters('creator', [
      'maxHeight',
      'maxWidth',
      'minHeight',
      'minWidth',
      'wardrobeHeight',
      'wardrobeWidth'
    ]),
    ...mapGetters('application', [
      'hasInventoryOnly',
      'hasDoorOnly',
      'hasFullWardrobe'
    ]),
  },
  data () {
    return {
      data: {
        height: 0,
        width: 0
      },
      isMeasurementModalVisible: false,
      loaded: false
    }
  },
  mounted () {
    let wardrobeSavedState = loadAutoSavedProject();

    if (wardrobeSavedState &&
      (this.hasDoorOnly !== wardrobeSavedState.hasDoorOnly ||
      this.hasInventoryOnly !== wardrobeSavedState.hasInventoryOnly ||
      this.hasFullWardrobe !== wardrobeSavedState.hasFullWardrobe)) {
      this.$store.commit('creator/resetInventory');
      this.$store.commit('creator/resetPrice');

      this.data.height = this.$store.getters['creator/minHeight'];
      this.data.width = this.$store.getters['creator/minWidth'];

      Vue.nextTick(() => {
        this.loaded = true;
      });
      return;
    }

    if (wardrobeSavedState && !this.$store.getters['application/getIsStepCompleted']('wardrobeDimensions')) {
      this.showLoadDataPopup(wardrobeSavedState);

      Vue.nextTick(() => {
        this.loaded = true;
      });
      return;
    }

    this.loadDataFromStore();
  },
  methods: {
    loadDataFromStore () {
      Vue.set(this, 'loaded', false);
      this.data.height = this.$store.getters['creator/wardrobeHeight'] || 0;
      this.data.width = this.$store.getters['creator/wardrobeWidth'] || 0;

      if (!this.data.height) {
        this.data.height = this.$store.getters['creator/minHeight'];
      }

      if (!this.data.width) {
        this.data.width = this.$store.getters['creator/minWidth'];
      }

      Vue.nextTick(() => {
        this.loaded = true;
      });
    },
    validateForm () {
      FormUtils.validate(this.$refs['dimensions-form'], this.confirmSubmitForm);
    },
    confirmSubmitForm () {
      if (this.$store.getters['application/getIsStepCompleted']('wardrobeDimensions') && this.areChangesMade()) {
        Vue.swal({
          title: 'Note',
          text: 'Ændringer i dette trin, kan have indflydelse på senere trin.',
          icon: 'warning',
          confirmButtonText: 'OK',
        }).then(() => {
          this.saveState(true);
          Vue.nextTick(() => {
            this.goNext();
          });
        });
      } else {
        this.saveState(true);

        Vue.nextTick(() => {
          this.goNext();
        });
      }
    },
    saveState (shouldMarkStepCompleted = false) {
      let isSettingChanged = this.areChangesMade();
      if (isSettingChanged) {
        this.$store.commit('creator/resetInventory');
      }
      this.$store.commit('creator/setWardrobeHeight', Number(this.data.height));
      this.$store.commit('creator/setWardrobeWidth', Number(this.data.width));

      if (shouldMarkStepCompleted) {
        this.$store.commit('application/setStepCompletion', { stepName: 'wardrobeDimensions', stepCompleted: true });
      }

      // this.$bus.$emit('update-wardrobe-price');
    },
    goNext () {
      if (this.hasInventoryOnly) {
        this.$router.push({ name: 'wardrobeInventory' });
        return;
      }

      if (this.hasDoorOnly) {
        this.$router.push({ name: 'wardrobeDoorsNumber' });
        return;
      }

      this.$router.push({ name: 'wardrobePosition' });
    },
    goBack () {
      this.saveState();

      Vue.nextTick(() => {
        this.$router.push({ name: 'startScreen' });
      });
    },
    areChangesMade () {
      return this.data.width !== this.$store.getters['creator/wardrobeWidth'] || this.data.height !== this.$store.getters['creator/wardrobeHeight'];
    },
    showLoadDataPopup (wardrobeSavedState) {
      Vue.swal({
        title: 'Gendan tidligere projekt',
        text: 'Der er et tidligere projekt tilgængeligt. Vil du indlæse den?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nej',
        showCloseButton: false,
        showLoaderOnConfirm: true,
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          let creatorSteps = Object.keys(wardrobeSavedState.stepsCompletion);
          for (let i = 0; i < creatorSteps.length; i++) {
            this.$store.commit('application/setStepCompletion', { stepName: creatorSteps[i], stepCompleted: wardrobeSavedState.stepsCompletion[creatorSteps[i]] });
          }

          this.$store.commit('creator/setDoors', wardrobeSavedState.doors);
          this.$store.commit('creator/setWardrobe', wardrobeSavedState.wardrobe);
          this.$store.commit('creator/setInventory', wardrobeSavedState.inventory);
        }

        Vue.nextTick(() => {
          this.loadDataFromStore();
        });
      })
    },
    showMeasurementModal () {
      this.isMeasurementModalVisible = true;
    },
    handleMeasurementModalClose () {
      this.isMeasurementModalVisible = false;
    }
  }
};
</script>
