var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-content creator-view"},[(!_vm.loaded)?_c('div',{staticClass:"loading-overlay"},[_vm._m(0)]):_vm._e(),_c('wardrobe-preview',{attrs:{"current-wardrobe-height":_vm.data.height,"current-wardrobe-width":_vm.data.width}}),_c('right-sidebar',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',[_vm._v("Angiv mål")])]},proxy:true},{key:"content",fn:function(){return [_c('validation-observer',{ref:"dimensions-form",attrs:{"tag":"form","id":"dimensionsForm"}},[_c('div',{staticClass:"form-field"},[_c('validation-provider',{attrs:{"rules":("required|min_value:" + _vm.minWidth + "|max_value:" + _vm.maxWidth),"name":"wardrobe-width"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:{
                'form-input': true,
                'is-invalid': errors.length
              },attrs:{"for":"wardrobe-width"}},[_c('span',{staticClass:"form-input-name"},[_vm._v("Bredde")]),_c('c-input',{attrs:{"type":"number","state":errors.length ? 'invalid' : null},model:{value:(_vm.data.width),callback:function ($$v) {_vm.$set(_vm.data, "width", $$v)},expression:"data.width"}}),_c('span',{staticClass:"form-input-appendix"},[_vm._v("mm")])],1),_c('div',{staticClass:"field-instructions"},[_vm._v(" OBS! Bredden skal være imellem "+_vm._s(_vm.minWidth)+"mm og "+_vm._s(_vm.maxWidth)+"mm. ")])]}}])})],1),_c('div',{staticClass:"form-field"},[_c('validation-provider',{attrs:{"rules":("required|min_value:" + _vm.minHeight + "|max_value:" + _vm.maxHeight),"name":"wardrobe-height"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('label',{class:{
                'form-input': true,
                'is-invalid': errors.length
              },attrs:{"for":"wardrobe-height"}},[_c('span',{staticClass:"form-input-name"},[_vm._v("Højde")]),_c('c-input',{attrs:{"type":"number","state":errors.length ? 'invalid' : null},model:{value:(_vm.data.height),callback:function ($$v) {_vm.$set(_vm.data, "height", $$v)},expression:"data.height"}}),_c('span',{staticClass:"form-input-appendix"},[_vm._v("mm")])],1),_c('div',{staticClass:"field-instructions"},[_vm._v(" OBS! Højden skal være imellem "+_vm._s(_vm.minHeight)+"mm og "+_vm._s(_vm.maxHeight)+"mm. ")])]}}])})],1)]),_c('div',{staticClass:"text-center mt-3 showcase-attention"},[_c('strong',[_vm._v("Se vores opmåling guide her")]),_c('p',[_vm._v("Husk at det er vigtigt at måle højde i 3 forskellige placeringer i forhold til loftet. Man skal angive det laveste mål i programmet for at få gavle tilpasset. Husk ved at vælge “væg til væg” placering, bregner vi 10 mm luft mellem væggene.")])]),_c('div',{staticClass:"showcase-data",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.showMeasurementModal.apply(null, arguments)}}},[_c('strong',{staticClass:"showcase-data-title text-center"},[_vm._v("Hvordan måler jeg rigtigt op?")]),_c('measurement-instructions')],1)]},proxy:true},{key:"footer",fn:function(){return [_c('navigation-buttons',{attrs:{"form-id":'dimensionsForm'},on:{"nextBtnClicked":_vm.validateForm,"backBtnClicked":_vm.goBack}})]},proxy:true}])}),_c('transition',{attrs:{"name":"fade"}},[(_vm.isMeasurementModalVisible)?_c('c-modal',{ref:"measurement-modal",class:{ 'is-visible': _vm.isMeasurementModalVisible, 'is-narrow is-centered': true },attrs:{"isPersistent":false,"is-visible":_vm.isMeasurementModalVisible},on:{"close":_vm.handleMeasurementModalClose}},[_c('c-modal-header',{staticClass:"text-center"},[_vm._v(" Hvordan måler jeg rigtigt op? ")]),_c('c-modal-body',[_c('measurement-instructions')],1)],1):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loader"},[_c('span',[_vm._v("Indlæsning af data…")])])}]

export { render, staticRenderFns }