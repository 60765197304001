<template>
  <button
    @click="handleClick"
    :class="buttonClass"
    :disabled="this.disabled">
    <span><slot/></span>
  </button>
</template>
<script>

export default {
  name: 'c-button',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'primary',
      validator: v => [
        'close',
        'primary',
        'secondary',
        'tile',
        'warning',
        'icon'
      ].includes(v)
    },
    rotateRight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSortAsc: false
    }
  },
  computed: {
    buttonClass () {
      let badgeCSSClass = ['c-btn'];

      if (this.theme) {
        badgeCSSClass.push(`c-btn-${this.theme}`);
      }

      if (this.isSmall) {
        badgeCSSClass.push('c-btn-small');
      }

      return badgeCSSClass.join(' ');
    }
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    }
  }
}
</script>
