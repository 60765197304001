<template>
  <div>
    <header class="site-header is-creator">
      <div class="wrapper">
        <div class="flex flex-align-items-center">
          <img
            class="logo"
            :src="require('@/assets/images/pandora-kitchen-logo.webp')"
            alt="Pandora Kitchen Logo" />

          <creator-progress />

          <c-button
            title="Oversigt"
            theme="icon"
            class="ml-auto"
            @click.prevent="showSummaryPopup">
            <div class="img-wrapper">
              <img
                class=""
                :src="require('@/assets/images/icons/material_shopping_cart.png')"
                alt="Ikon for indkøbskurv" />
              </div>
            <span class="text">Oversigt</span>
          </c-button>
        </div>
      </div>
    </header>

    <main class="site-content">
      <slot />
    </main>
  </div>
</template>

<script>
import CreatorProgress from '@/components/layout/CreatorProgress.vue';

export default {
  name: 'main-view',
  components: {
    'creator-progress': CreatorProgress
  },
  data() {
    return {
    };
  },
  methods: {
    showSummaryPopup () {
      this.$store.commit('application/setIsSummaryPopupVisible', true);
    }
  }
};
</script>
