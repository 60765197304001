<template>
  <div
    ref="tooltip"
    class="tooltip">
    ?
    <div
      ref="tooltipText"
      v-pure-html="text"
      class="tooltip-text"
      :style="currentTooltipStyle">
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'tooltip',
  props: {
    containerElementClass: {
      required: true,
      type: String
    },
    text: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      containerElement: null,
      currentTooltipStyle: ''
    };
  },
  mounted () {
    this.containerElement = this.$refs['tooltip'].closest('.' + this.containerElementClass);
    window.addEventListener('resize', this.setTooltipTextWidth);
    Vue.nextTick(() => {
      this.setTooltipTextWidth();
    });
  },
  methods: {
    setTooltipTextWidth () {
      let tooltipBoundingBox = this.$refs['tooltip'].getBoundingClientRect();
      let parentBoundingBox = this.containerElement.getBoundingClientRect();
      let maxTooltipTextWidth = Math.min(Math.floor(parentBoundingBox.width - 20), 250);

      let rightPos = -5;

      let rightPosOnScreen = tooltipBoundingBox.x + tooltipBoundingBox.width + 5;

      if ((rightPosOnScreen - maxTooltipTextWidth) < parentBoundingBox.x + 5) {
        let difference = (parentBoundingBox.x + 5) - (rightPosOnScreen - maxTooltipTextWidth);
        rightPos -= difference;
      } else if (rightPosOnScreen > ((parentBoundingBox.x + parentBoundingBox.width) - 5)) {
        let difference = ((parentBoundingBox.x + parentBoundingBox.width) - 5) - rightPosOnScreen;
        rightPos -= difference;
      }
      this.currentTooltipStyle = `width: ${maxTooltipTextWidth}px; right: ${rightPos}px;`;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setTooltipTextWidth);
  }
}
</script>
