<template>
  <div class="view-content creator-view">
    <div
      v-if="!loaded"
      class="loading-overlay">
      <div class="loader">
        <span>Indlæsning af data&hellip;</span>
      </div>
    </div>

    <wardrobe-preview>
      <template v-slot:content>
        <div
          v-if="loaded"
          class="wardrobe-layout mt-5 ml-auto mr-auto"
          :data-doors-count="doorsNumber">
          <div
            class="wardrobe-front"
            v-bind:style="{ 'aspect-ratio': (wardrobeWidth / wardrobeHeight) }">

            <div
              v-for="(door, index) in doorsNumber"
              :key="'door-' + index + '-'"
              :class="{
                'wardrobe-door': true,
                'is-active': selectedDoorIndex === index
              }"
              :active="selectedDoorIndex === index"
              @click.prevent="setSelectedDoorSection(index)">
              <div
                v-for="n in getDivisionsNumber(data.doorsItems[index].division_id)"
                :key="'door-' + index + '-part-' + n + '-display'"
                :class="{
                  'door-part': true,
                  'is-active': selectedDoorIndex === index
                }">
              </div>

              <span class="wardrobe-dimensions wardrobe-door-width">Vælg døre-deling</span>
            </div>
          </div>
        </div>
      </template>
    </wardrobe-preview>

    <right-sidebar>
      <template v-slot:header>
        <h2>Skydelåger design</h2>
      </template>
      <template v-slot:content>

        <validation-observer
          v-if="loaded"
          ref="divisions-selection-form"
          tag="form"
          id="doorsDivisionForm">
          <validation-provider
            rules="required"
            name="door-divisions-options"
            class="grid-cols-1"
            v-slot="{ errors }"
            :key="'division-for-door-' + selectedDoorIndex">
            <c-form-radio
              v-for="(option, optionIndex) in availableOptionsDoorDivisions"
              :key="optionIndex"
              class="door-division-wrapper ml-auto mr-auto"
              :withImage="true"
              :value="option.id"
              v-model="data.doorsItems[selectedDoorIndex].division_id"
              :disabled="option.disabled"
              @change="updatePrice">
              <img
                class="door-count-img"
                :style="{'height': '100%'}"
                :src="require('@/assets/images/svg/divide' + option.divide + '.svg')"
                :alt="'Dør-deling - ' + option.divide" />
              <span class="c-form-radio-label door-divisions-name">{{ option.name }}</span>
            </c-form-radio>
            <div class="invalid-feedback absolute">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </validation-observer>
      </template>

      <template v-slot:footer>
        <navigation-buttons
          :form-id="'doorsDivisionForm'"
          @nextBtnClicked="validateForm"
          @backBtnClicked="goBack" />
      </template>
    </right-sidebar>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'wardrobe-doors-division',
  computed: {
    ...mapGetters('creator', [
      'wardrobeHeight',
      'wardrobeWidth',
      'doorsNumber'
    ]),
    availableOptionsDoorDivisions () {
      return this.$store.getters['apiData/getAvailableOptionsDoorDivisions'](this.wardrobeHeight)
    }
  },
  data () {
    return {
      data: {
        doorsItems: []
      },
      loaded: false,
      loadError: false,
      selectedDoorIndex: 0
    }
  },
  mounted () {
    this.data.doorsItems = JSON.parse(JSON.stringify(this.$store.getters['creator/doorsItems']));

    let smallestDivideNumber = this.availableOptionsDoorDivisions.reduce((prev, curr) => prev.divide < curr.divide ? prev : curr);

    for (let i = 0; i < this.data.doorsItems.length; i++) {
      if (!this.data.doorsItems[i].division_id) {
        this.data.doorsItems[i].division_id = smallestDivideNumber.id;
      } else if (!this.availableOptionsDoorDivisions.find(item => item.id === this.data.doorsItems[i].division_id)) {
        this.data.doorsItems[i].division_id = smallestDivideNumber.id;
      }
    }

    this.loaded = true;
  },
  methods: {
    setSelectedDoorSection (selectedIndex) {
      Vue.set(this, 'selectedDoorIndex', selectedIndex);
    },
    validateForm () {
      FormUtils.validate(this.$refs['divisions-selection-form'], this.goNextStep);
    },
    saveState (shouldMarkStepCompleted = false) {
      this.updateDoorFillings();

      Vue.nextTick(() => {
        this.$store.commit('creator/setDoorsItems', this.data.doorsItems);

        this.updatePrice();

        if (shouldMarkStepCompleted) {
          this.$store.commit('application/setStepCompletion', { stepName: 'wardrobeDoorsDivision', stepCompleted: true });
        }
      })
    },
    goNextStep () {
      this.saveState(true);
      Vue.nextTick(() => {
        this.$router.push({ name: 'wardrobeDoorsMaterial' });
      });
    },
    goBack () {
      this.saveState();

      Vue.nextTick(() => {
        this.$router.push({ name: 'wardrobeDoorsNumber' });
      });
    },
    updatePrice () {
      this.$bus.$emit('update-wardrobe-price');
    },
    getDivisionsNumber (divisionID) {
      if (!divisionID) {
        return 1;
      }

      let divisionFound = this.availableOptionsDoorDivisions.find(item => item.id === divisionID);

      if (divisionFound && divisionFound.divide) {
        return divisionFound.divide;
      }
      return 1;
    },
    updateDoorFillings () {
      for (let i = 0; i < this.data.doorsItems.length; i++) {
        let fillingsCount = this.data.doorsItems[i].fillings.length;
        let divisionsNumber = this.getDivisionsNumber(this.data.doorsItems[i].division_id);

        if (fillingsCount > divisionsNumber) {
          this.data.doorsItems[i].fillings.splice(divisionsNumber);
        } else {
          while (this.data.doorsItems[i].fillings.length < divisionsNumber) {
            this.data.doorsItems[i].fillings.push({ filling_id: 0, soft_close: false });
          }
        }
      }
    }
  }
};
</script>
