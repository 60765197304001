<template>
  <section class="sidebar">
    <header class="sidebar-header flex">
      <template v-if="isSummaryVisible">
        <h2>
          <template v-if="isSummaryPopupVisible">
            Oversigt
          </template>
          <template v-else>
            Bekræftelse
          </template>
        </h2>

        <c-button
          v-if="isSummaryPopupVisible"
          theme="close"
          class="ml-auto"
          @click.prevent="closeSummaryPopup" />
      </template>
      <template v-else>
        <slot name="header"></slot>
      </template>
    </header>

    <div class="sidebar-content">
      <template v-if="isSummaryVisible">
        <order-summary />
      </template>
      <template v-else>
        <slot name="content"></slot>
      </template>
    </div>

    <footer class="sidebar-footer">
      <div v-if="isSummaryVisible">
        <div class="flex mt-auto summary-total">
          <strong class="mr-2">Total pris:</strong>
          <div class="ml-auto">
            <span
              v-if="price.total_price_before_discount"
              class="total-price-discount mr-2">
              {{ getPriceFormatted(price.total_price_before_discount) }}
            </span>
            <span class="total-price">
              {{ getPriceFormatted(price.total_price) }}
            </span>
          </div>
        </div>
      </div>
      <template v-if="!isSummaryPopupVisible">
        <slot name="footer"></slot>
      </template>
    </footer>

  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import OrderSummary from '@/components/layout/OrderSummary.vue';
import CustomDecimals from '@/utils/CustomDecimals.js';

export default {
  name: 'right-sidebar',
  components: {
    'order-summary': OrderSummary,
  },
  computed: {
    ...mapGetters('application', [
      'isSummaryPopupVisible'
    ]),
    ...mapGetters('creator', [
      'price'
    ]),
    isSummaryVisible () {
      if (this.isSummaryPopupVisible) {
        return true;
      }

      if (this.$route.query && this.$route.query.orderSummary) {
        return true;
      }

      return false;
    }
  },
  methods: {
    closeSummaryPopup () {
      this.$store.commit('application/setIsSummaryPopupVisible', false);
    },
    getPriceFormatted (price) {
      return CustomDecimals.getPriceFormatted(price);
    }
  }
}
</script>
