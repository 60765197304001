<template>
  <div class="view-content creator-view">
    <div
      v-if="!loaded"
      class="loading-overlay">
      <div class="loader">
        <span>Indlæsning af data&hellip;</span>
      </div>
    </div>

    <wardrobe-preview />

    <right-sidebar>
      <template v-slot:header>
        <h2>Placering</h2>
      </template>

      <template v-slot:content>
        <validation-observer
          ref="position-form"
          tag="form"
          id="positionForm">

          <div class="form-group">
            <validation-provider
              rules="required"
              name="wardrobe-position-with-gable"
              v-slot="{ errors }"
              tag="div"
              class="full-width">
              <div class="wardrobe-positions">
                <c-form-radio
                  v-for="(position, index) in positionsWithGable"
                  :key="index"
                  :withImage="true"
                  :value="position.id"
                  v-model="data.wardrobePosition"
                  :class="{ 'relative': true, 'is-invalid': errors.length }">
                  <tooltip
                    :container-element-class="'sidebar-content'"
                    :text="getPositionDescription(position.id)" />
                  <img
                    :src="position.image"
                    :alt="'Placering - ' + position.name"
                    class="position-image" />
                  <span class="c-form-radio-label">{{ position.name }}</span>
                </c-form-radio>
              </div>
            </validation-provider>
          </div>

          <div class="form-group modificate">
              <validation-provider
                :rules="isGableEnabled ? 'required' : ''"
                name="wardrobe-gable"
                v-slot="{ errors }"
                tag="div"
                class="flex flex-column">
                <div class="wardrobe-positions new-style-radio">
                 <span>Dybde:</span>
                  <c-form-radio
                    v-for="(gable, index) in optionsGable"
                    :key="index"
                    :value="gable.id"
                    v-model="data.gableID"
                    :class="{ 'is-invalid': errors.length }"
                    :disabled="!isGableEnabled">
                    <span class="c-form-radio-label">{{ gable.name }}</span>
                  </c-form-radio>
                </div>
                <div class="invalid-feedback text-center">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>

          <div class="form-group mt-1">
            <validation-provider
              rules="required"
              name="wardrobe-position-no-gable"
              v-slot="{ errors }"
              tag="div"
              class="full-width centered">
              <div class="wardrobe-positions">
                <c-form-radio
                  v-for="(position, index) in positionsWithoutGable"
                  :key="index"
                  :withImage="true"
                  :value="position.id"
                  v-model="data.wardrobePosition"
                  :class="{ 'relative': true, 'is-invalid': errors.length }">
                  <tooltip
                    :container-element-class="'sidebar-content'"
                    :text="getPositionDescription(position.id)" />
                  <img
                    :src="position.image"
                    :alt="'Placering - ' + position.name"
                    class="position-image" />
                  <span class="c-form-radio-label">{{ position.name }}</span>
                </c-form-radio>
              </div>
            </validation-provider>
          </div>

          <div class="showcase-attention mt-5">
            <strong>Har du ujævne vægge?</strong>
            <p class="mb-1">Tilføj en anslag liste til din ordre. Et anslag monteres til væggen for rette skævheder op.</p>

            <!-- to jest tylko jesli jest do sciany zprzynajmniej jednej strony -->
            <div class="form-group">
              <validation-provider
                :rules="isGableEnabled ? 'required' : ''"
                name="has-uneven-walls"
                v-slot="{ errors }"
                tag="div"
                class="flex flex-column">
                <div class="wardrobe-positions flex flex-wrap  new-style-radio">
                  <c-form-radio
                    :value="true"
                    v-model="data.useImpact"
                    :class="{ 'is-invalid': errors.length }"
                    :disabled="!isImpactEnabled">
                    <span class="c-form-radio-label bold">Tilføj anslag</span>
                  </c-form-radio>
                  <c-form-radio
                    :value="false"
                    v-model="data.useImpact"
                    :class="{ 'is-invalid': errors.length }"
                    :disabled="!isImpactEnabled">
                    <span class="c-form-radio-label bold">Nej tak til anslag</span>
                  </c-form-radio>
                </div>
              </validation-provider>
            </div>
          </div>
        </validation-observer>
      </template>

      <template v-slot:footer>
        <navigation-buttons
          :form-id="'positionForm'"
          @nextBtnClicked="validateForm"
          @backBtnClicked="goBack" />
      </template>
    </right-sidebar>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import FormUtils from '@/utils/FormUtils.js';
import Tooltip from '@/components/layout/Tooltip.vue';

export default {
  name: 'wardrobe-position',
  components: {
    'tooltip': Tooltip
  },
  computed: {
    ...mapGetters('apiData', [
      'optionsWardrobePositions',
      'optionsGable'
    ]),
    ...mapGetters('application', ['hasFullWardrobe']),
    wardrobePositionObject () {
      if (!this.data.wardrobePosition) {
        return false;
      }

      return this.optionsWardrobePositions.find(item => item.id === this.data.wardrobePosition);
    },
    isGableEnabled () {
      if (!this.wardrobePositionObject) {
        return false;
      }

      return this.wardrobePositionObject.has_gable;
    },
    isImpactEnabled () {
      if (!this.wardrobePositionObject) {
        return false;
      }

      return this.wardrobePositionObject.has_impact;
    },
    positionsWithGable () {
      return this.optionsWardrobePositions.filter(item => item.has_gable);
    },
    positionsWithoutGable () {
      return this.optionsWardrobePositions.filter(item => !item.has_gable);
    },
    hasRightAdjustment () {
      if (!this.wardrobePositionObject.has_impact || !this.data.useImpact) {
        return false;
      }

      return this.wardrobePositionObject.impact_position.includes('right');
    },
    hasLeftAdjustment () {
      if (!this.wardrobePositionObject.has_impact || !this.data.useImpact) {
        return false;
      }

      return this.wardrobePositionObject.impact_position.includes('left');
    }
  },
  data () {
    return {
      data: {
        gableID: null,
        useImpact: false,
        wardrobePosition: null,
      },
      loaded: false
    }
  },
  mounted () {
    Vue.set(this, 'loaded', false);
    this.data.gableID = this.$store.getters['creator/wardrobeGableId'];
    this.data.useImpact = this.$store.getters['creator/wardrobeImpact'];
    this.data.wardrobePosition = this.$store.getters['creator/wardrobePositionId'];

    Vue.nextTick(() => {
      this.loaded = true;
    });
  },
  methods: {
    validateForm () {
      FormUtils.validate(this.$refs['position-form'], this.confirmSubmitForm);
    },
    confirmSubmitForm () {
      if (this.$store.getters['application/getIsStepCompleted']('wardrobePosition') && this.areChangesMade()) {
        Vue.swal({
          title: 'Note',
          text: 'Ændringer i dette trin, kan have indflydelse på senere trin.',
          icon: 'warning',
          confirmButtonText: 'OK',
        }).then(() => {
          this.saveState(true);
          Vue.nextTick(() => {
            // this.$bus.$emit('update-wardrobe-price');
            this.$router.push({ name: 'wardrobeDoorsNumber' });
          });
        });
      } else {
        this.saveState(true);

        Vue.nextTick(() => {
          // this.$bus.$emit('update-wardrobe-price');
          this.$router.push({ name: 'wardrobeDoorsNumber' });
        });
      }
    },
    saveState (shouldMarkStepCompleted = false) {
      let isSettingChanged = this.areChangesMade();
      if (isSettingChanged) {
        // TODO: to chyba nie tutaj, chyba w jakimś następnym kroku?
        this.$store.commit('creator/resetInventory');
      }

      if (this.wardrobePositionObject.has_gable) {
        this.$store.commit('creator/setWardrobeGableId', this.data.gableID);
      } else {
        this.$store.commit('creator/setWardrobeGableId', 0);
      }

      this.$store.commit('creator/setWardrobePositionId', this.data.wardrobePosition);

      if (!this.wardrobePositionObject.has_impact) {
        this.$store.commit('creator/setWardrobeUseImpact', false);
      } else {
        this.$store.commit('creator/setWardrobeUseImpact', this.data.useImpact);
      }

      // TODO: zweryfikować te ustawienia
      this.$store.commit('creator/setWardrobeLeftEndAdjustment', this.hasLeftAdjustment);
      this.$store.commit('creator/setWardrobeLeftStrokeAdjustment', this.hasLeftAdjustment);
      this.$store.commit('creator/setWardrobeRightEndAdjustment', this.hasRightAdjustment);
      this.$store.commit('creator/setWardrobeRightStrokeAdjustment', this.hasRightAdjustment);

      if (shouldMarkStepCompleted) {
        this.$store.commit('application/setStepCompletion', { stepName: 'wardrobePosition', stepCompleted: true });
      }
    },
    goBack () {
      this.saveState();

      Vue.nextTick(() => {
        this.$router.push({ name: 'wardrobeDimensions' });
      });
    },
    areChangesMade () {
      return this.data.wardrobePosition !== this.$store.getters['creator/wardrobePositionId'] || this.data.gableID !== this.$store.getters['creator/wardrobeGableId'] || this.data.useImpact !== this.$store.getters['creator/wardrobeImpact'];
    },
    getPositionDescription (positionID) {
      // TODO: to powinno przyjść z backend
      if (positionID === 8) {
        return 'Skab er placeret med venstre side op mod væggen og fri plads på højere side. Udvendig gavl skal vælges.';
      }

      if (positionID === 10) {
        return 'Skab er placeret med højre side op mod væggen og fri plads på venstre side. Udvedig gavl skal vælges.';
      }

      if (positionID === 11) {
        return 'Skab er placeret mellem to væggene og har ingen udvendige gavle. Dybde på inventar er  XXX.';
      }

      if (positionID === 12) {
        return 'Skab er placeret med fri plads på begge sider. Udvendige gavle skal vælges.';
      }

      return '';
    }
  }
};
</script>

<style lang="scss" scoped>
.position-image {
  max-height: 60px;
  max-width: 100px;
}
</style>
