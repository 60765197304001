/**
 * Core
 */
import Vue from 'vue';
import Router from 'vue-router';

/**
 * Views
 */
import Splashscreen from '@/views/SplashScreen';

// lazy-loaded parts
import StartScreen from '@/views/StartScreen';

import WardrobeDimensions from '@/views/WardrobeDimensions';
import WardrobePosition from '@/views/WardrobePosition';
import WardrobeDoorsNumber from '@/views/WardrobeDoorsNumber';
import WardrobeDoorsDivision from '@/views/WardrobeDoorsDivision';
import WardrobeDoorsMaterial from '@/views/WardrobeDoorsMaterial';
import WardrobeInventory from '@/views/WardrobeInventory';
import PlaceOrder from '@/views/PlaceOrder';
import OrderComplete from '@/views/OrderComplete';
import OrderCancelled from '@/views/OrderCancelled';

// Avoid NavigationDuplicated errors
const originalPush = Router.prototype.push;

Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch(error => {
    if (error && error.name !== 'NavigationDuplicated') {
      throw error;
    }
  });
};

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/loading',
      component: Splashscreen,
      name: 'splashScreen',
      meta: {
        layout: 'default'
      }
    },
    {
      path: '/',
      redirect: '/start-screen'
    },
    {
      path: '/start-screen',
      component: StartScreen,
      name: 'startScreen',
      meta: {
        layout: 'basic'
      }
    },
    {
      path: '/wardrobe-dimensions',
      component: WardrobeDimensions,
      name: 'wardrobeDimensions',
      meta: {
        layout: 'creator'
      }
    },
    {
      path: '/wardrobe-position',
      component: WardrobePosition,
      name: 'wardrobePosition',
      meta: {
        layout: 'creator'
      }
    },
    {
      path: '/wardrobe-doors-number',
      component: WardrobeDoorsNumber,
      name: 'wardrobeDoorsNumber',
      meta: {
        layout: 'creator'
      }
    },
    {
      path: '/wardrobe-doors-division',
      component: WardrobeDoorsDivision,
      name: 'wardrobeDoorsDivision',
      meta: {
        layout: 'creator'
      }
    },
    {
      path: '/wardrobe-doors-material',
      component: WardrobeDoorsMaterial,
      name: 'wardrobeDoorsMaterial',
      meta: {
        layout: 'creator'
      }
    },
    {
      path: '/wardrobe-inventory',
      component: WardrobeInventory,
      name: 'wardrobeInventory',
      meta: {
        layout: 'creator'
      }
    },
    {
      path: '/place-order',
      component: PlaceOrder,
      name: 'placeOrder',
      meta: {
        layout: 'basic',
        customTile: {
          text: 'Kundeoplysninger',
        }
      }
    },
    {
      path: '/order-complete',
      component: OrderComplete,
      name: 'orderComplete',
      meta: {
        layout: 'basic',
        customTile: {
          text: 'Din bestilling er nu modtaget',
        }
      }
    },
    {
      path: '/order-cancelled',
      component: OrderCancelled,
      name: 'orderCancelled',
      meta: {
        layout: 'basic',
        customTile: {
          text: 'Bestilling Annulleret',
          additionalClass: 'alert'
        }
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (!from.name && to.name !== 'splashScreen') {
    next({ name: 'splashScreen' });
    return;
  }

  next();
});

export default router;
