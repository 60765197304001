<template>
  <div class="view-content creator-view">
    <div
      v-if="loadError"
      class="alert alert-danger mx-auto my-auto px-5">
      Fejl ved indlæsning af data.
      <a
        href="javascript:window.location.reload();"
        class="alert-link">
        Opdatere siden
      </a>
      for at prøve igen.
    </div>
    <template v-else>
      <div
        v-if="!allDataLoaded"
        class="loading-overlay">
        <div class="loader">
          <span>Indlæsning af data&hellip;</span>
        </div>
      </div>

      <wardrobe-preview>
        <template v-slot:content>
          <div
            v-if="selectedDoorsNumberOption"
            class="wardrobe-layout mt-5 ml-auto mr-auto"
            :data-doors-count="selectedDoorsNumberOption.number">
            <div
              class="wardrobe-front"
              v-bind:style="{ 'aspect-ratio': (wardrobeWidth / wardrobeHeight) }">
              <div
                v-for="(door, index) in selectedDoorsNumberOption.number"
                :key="index"
                class="wardrobe-door">
                <span class="wardrobe-dimensions wardrobe-door-width">Dør bredde: {{ selectedDoorsNumberOption.door_width || 0 }} mm</span>
              </div>
            </div>
          </div>
        </template>
      </wardrobe-preview>

      <right-sidebar>
        <template v-slot:header>
          <h2>Skydelåger design</h2>
        </template>

        <template v-slot:content>
          <validation-observer
            v-if="allDataLoaded"
            ref="doors-number-form"
            tag="form"
            id="doorsNumberForm">

            <div class="form-group">
              <validation-provider
                rules="required"
                name="number-of-doors"
                v-slot="{ errors }"
                class="door-no-selection"
                tag="div">

                <c-form-radio
                  v-for="(countOption, index) in doorsCountOptions"
                  :key="index"
                  :withImage="true"
                  :value="countOption.number"
                  :checked="countOption.number === data.doorsNumber"
                  class="mr-0"
                  v-model="data.doorsNumber"
                  :class="{ 'is-invalid': errors.length }">
                  <span>{{countOption.name}}</span>
                  <!-- TODO: name powinien byc przetłumaczony -->
                  <img
                    class="door-count-img"
                    :src="require('@/assets/images/door-count/' + countOption.number + '-doors.png')"
                    :alt="'Antal Skydedøre - ' + countOption.number" />
                </c-form-radio>
              </validation-provider>
            </div>

            <div class="form-group flex-justify-content-center">
              <validation-provider
                name="door-sofclose"
                v-slot="{ errors }">
                <div>
                  <c-checkbox
                    v-model="data.hasSoftClose"
                    @change="updatePrice">
                    Tilføj softclose på alle låger
                  </c-checkbox>
                </div>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </validation-observer>
        </template>

        <template v-slot:footer>
          <navigation-buttons
            :form-id="'doorsNumberForm'"
            @nextBtnClicked="validateForm"
            @backBtnClicked="goBack" />
        </template>
      </right-sidebar>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'wardrobe-doors-numer',
  computed: {
    ...mapGetters('creator', [
      'wardrobeHeight',
      'wardrobeWidth',
      'wardrobeGableId',
      'wardrobeImpact',
      'wardrobePositionId'
    ]),
    allDataLoaded () {
      return this.loaded && this.doorsCountOptionsLoaded;
    },
    selectedDoorsNumberOption () {
      if (!this.doorsCountOptionsLoaded || this.loadError) {
        return null;
      }

      return this.doorsCountOptions.find(item => item.number === this.data.doorsNumber);
    }
  },
  data () {
    return {
      data: {
        doorsNumber: null,
        hasSoftClose: false // TODO: zamieniamy na flagę ogólną
      },
      doorsCountOptions: [],
      doorsCountOptionsLoaded: false,
      loaded: false,
      loadError: false
    }
  },
  mounted () {
    this.loadOptionsData();

    this.data.hasSoftClose = this.$store.getters['creator/wardrobeSoftClose'];

    this.loaded = true;
  },
  methods: {
    loadOptionsData () {
      FormUtils.loadData(this, {
        method: 'post',
        endpoint: '/api/doors/calculate/' + this.wardrobeWidth,
        loadedKey: 'doorsCountOptionsLoaded',
        outputKey: 'doorsCountOptions',
        errorKey: 'loadError',
        noPagination: true,
        params: {
          has_gable: !!this.wardrobeGableId,
          has_impact: this.wardrobeImpact,
          wardrobe_position: this.wardrobePositionId
        },
        successAction: (response) => {
          let doorsNumberInStore = this.$store.getters['creator/doorsNumber'];

          if (doorsNumberInStore) {
            let currentOption = response.find(item => item.number === doorsNumberInStore)
            if (currentOption) {
              this.data.doorsNumber = doorsNumberInStore;
            } else {
              this.data.doorsNumber = response[0].number;
              this.$store.commit('creator/setDoorsNumber', 0);
            }
          } else {
            this.data.doorsNumber = response[0].number;
          }
          Vue.nextTick(() => {
            this.updatePrice();
          })
        }
      });
    },
    validateForm () {
      FormUtils.validate(this.$refs['doors-number-form'], this.confirmSubmitForm);
    },
    confirmSubmitForm () {
      if (this.$store.getters['application/getIsStepCompleted']('wardrobeDoorsNumber') && this.areChangesMade()) {
        Vue.swal({
          title: 'Note',
          text: 'Ændringer i dette trin, kan have indflydelse på senere trin.',
          icon: 'warning',
          confirmButtonText: 'OK',
        }).then(() => {
          this.saveState();

          Vue.nextTick(() => {
            this.updatePrice();
            this.$router.push({ name: 'wardrobeDoorsDivision' });
          });
        });
      } else {
        this.saveState();

        Vue.nextTick(() => {
          this.updatePrice();
          this.$router.push({ name: 'wardrobeDoorsDivision' });
        });
      }
    },
    saveState (shouldMarkStepCompleted = false) {
      this.$store.commit('creator/setDoorsNumber', this.data.doorsNumber);
      this.$store.commit('creator/setWardrobeSoftClose', this.data.hasSoftClose);

      this.$store.commit('creator/setDoorsNumberObject', this.selectedDoorsNumberOption);

      if (shouldMarkStepCompleted) {
        this.$store.commit('application/setStepCompletion', { stepName: 'wardrobeDoorsNumber', stepCompleted: true });
      }
    },
    goBack () {
      this.saveState();

      if (this.$store.getters['application/hasDoorOnly']) {
        this.$router.push({ name: 'wardrobeDimensions' });
        return;
      }

      Vue.nextTick(() => {
        this.$router.push({ name: 'wardrobePosition' });
      });
    },
    areChangesMade () {
      let doorsCountInStore = this.$store.getters['creator/doorsNumber'];
      return this.data.doorsNumber !== doorsCountInStore;
    },
    updatePrice () {
      if (!this.doorsCountOptionsLoaded) {
        return;
      }
      this.$bus.$emit('update-wardrobe-price');
    }
  }
};
</script>
