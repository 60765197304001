
<template>
  <div class="c-modal">
    <div
      class="c-modal-dialog"
      role="document">
      <div
        class="c-modal-content card"
        v-on-clickaway="away">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'c-modal',
  mixins: [clickaway],
  props: {
    isPersistent: {
      default: false,
      type: Boolean
    },
    isVisible: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
    }
  },
  methods: {
    away () {
      if (this.isPersistent || !this.isVisible) {
        return
      }
      this.$emit('close');
    }
  }
}
</script>
