<template>
  <div>
    <header class="site-header is-basic">
      <div class="wrapper">
        <div class="flex flex-align-items-center">
          <img
            class="logo"
            :src="require('@/assets/images/pandora-kitchen-logo.webp')"
            alt="Pandora Kitchen Logo" />
        </div>
      </div>
    </header>
    <main class="site-content">
      <h2
        v-if="$route.meta.customTile"
        :class="customTitleClassList">
        {{ $route.meta.customTile.text }}
      </h2>
      <div
        v-else
        class="alert alert-info">
        <p class="wrapper text-bold">Vi beregner 10mm luft fra husvæggene.</p>
      </div>
      <div
        v-if="isLoadError"
        class="alert alert-danger mx-auto my-auto px-5">
        Fejl ved indlæsning af data.
        <a
          href="javascript:window.location.reload();"
          class="alert-link">
          Opdatere siden
        </a>
        for at prøve igen.
      </div>
      <slot v-else />
    </main>
  </div>
</template>

<script>

export default {
  name: 'main-view',
  props: {
    isLoadError: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    customTitleClassList () {
      let classList = ['card-heading', 'text-center'];
      if (this.$route.meta && this.$route.meta.customTile && this.$route.meta.customTile.additionalClass) {
        classList.push(this.$route.meta.customTile.additionalClass);
      }
      return classList.join(' ');
    }
  }
};
</script>
