// Vendors
import Vue from 'vue';
import Axios from 'axios';
import VueAxios from 'vue-axios';
import DOMPurify from 'dompurify';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import vSelect from 'vue-select';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.all.min.js';
import VueKonva from 'vue-konva';
import VueCookies from 'vue-cookies';

// Styles
import '@/assets/scss/style.scss';
import 'vue-select/dist/vue-select.css';
import '@/assets/scss/vue-select.override.scss';
import '@/assets/scss/vue-sweetalert2.override.scss';

// Core
import App from './App.vue';
import AppConfig from './.env';
import router from './router';
import store from './store/index';

// Layouts
import Default from '@/layouts/Default.vue';
import Basic from '@/layouts/Basic.vue';
import Creator from '@/layouts/Creator.vue';

// Helpers
import addVeeValidateRules from '@/utils/VeeValidateRules.js';
// import SanitizeUtils from '@/utils/SanitizeUtils';

// Custom components
import CInput from '@/components/forms/CInput.vue';
import CRadioButton from '@/components/forms/CRadioButton.vue';
import CCheckbox from '@/components/forms/CCheckbox.vue';
import CButton from '@/components/core/CButton.vue';
import CModal from '@/components/layout/CModal.vue';
import CModalHeader from '@/components/layout/CModalHeader.vue';
import CModalBody from '@/components/layout/CModalBody.vue';
import CModalFooter from '@/components/layout/CModalFooter.vue';

import WardrobePreview from '@/components/layout/WardrobePreview.vue';
import RightSidebar from '@/components/layout/RightSidebar.vue';
import NavigationButtons from '@/components/layout/NavigationButtons.vue';

Vue.component('c-input', CInput);
Vue.component('c-form-radio', CRadioButton);
Vue.component('c-checkbox', CCheckbox);
Vue.component('c-button', CButton);
Vue.component('c-modal', CModal);
Vue.component('c-modal-header', CModalHeader);
Vue.component('c-modal-body', CModalBody);
Vue.component('c-modal-footer', CModalFooter);

Vue.component('wardrobe-preview', WardrobePreview);
Vue.component('right-sidebar', RightSidebar);
Vue.component('navigation-buttons', NavigationButtons);

// TODO: posprzątać tu

// Global components
Vue.component('default-layout', Default);
Vue.component('basic-layout', Basic);
Vue.component('creator-layout', Creator);
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
Vue.component('v-select', vSelect);

// Configure vendors
addVeeValidateRules(extend);

// Axios
window.axios = Axios;
Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.baseURL = AppConfig.apiServerUrl;
Vue.prototype.$http.defaults.headers['Cache-Control'] = 'no-store,max-age=0';

Vue.prototype.$http.interceptors.response.use(undefined, (error) => {
  if (
    error.response.status === 401 &&
    router.currentRoute.path !== '/loading'
  ) {
    window.location.reload();
  }

  return Promise.reject(error);
});

// Other
Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue();
Vue.use(VueAxios, Axios);
Vue.use(VueSweetalert2);
Vue.use(VueKonva);
Vue.use(VueCookies);

// DOMPurify configuration
DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }

  // set non-HTML/MathML links to xlink:show=new
  if (!node.hasAttribute('target') && (node.hasAttribute('xlink:href') || node.hasAttribute('href'))) {
    node.setAttribute('xlink:show', 'new');
  }
});

// Register v-pure-html directive
Vue.directive('pure-html', {
  inserted: (el, binding) => {
    if (binding.oldValue === binding.value) {
      return;
    }
    el.innerHTML = DOMPurify.sanitize(binding.value);
  },
  update: (el, binding) => {
    if (binding.oldValue === binding.value) {
      return;
    }
    el.innerHTML = DOMPurify.sanitize(binding.value);
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
