var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-content creator-view materials-selection"},[(!_vm.loaded)?_c('div',{staticClass:"loading-overlay"},[_vm._m(0)]):_vm._e(),_c('wardrobe-preview',{scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.loaded)?_c('validation-observer',{ref:"materials-form",staticClass:"wardrobe-layout with-materials mt-5 ml-auto mr-auto",attrs:{"data-doors-count":_vm.doorsNumber,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{class:{
            'wardrobe-front':true,
            'is-invalid': invalid && _vm.validationTriggered
            },style:({ 'aspect-ratio': (_vm.wardrobeWidth / _vm.wardrobeHeight) })},[_vm._l((_vm.doorsNumber),function(door,index){return _c('div',{key:'door-' + index,staticClass:"wardrobe-door"},_vm._l((_vm.getDivisionsNumber(_vm.data.doorsItems[index].division_id)),function(n){return _c('validation-provider',{key:'door-' + index + '-part-' + n + '-' + (_vm.data.doorsItems[index].fillings[n - 1].filling_id || 0),staticClass:"door-part-validation",attrs:{"rules":"required","name":'door-' + index + '-part-' + n},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.doorsItems[index].fillings[n - 1].filling_id),expression:"data.doorsItems[index].fillings[n - 1].filling_id"}],key:(_vm.data.doorsItems[index].fillings[n - 1].filling_id || 0),domProps:{"value":(_vm.data.doorsItems[index].fillings[n - 1].filling_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data.doorsItems[index].fillings[n - 1], "filling_id", $event.target.value)}}}),_c('div',{key:'door-' + index + '-part-' + n + '-display',class:{
                  'door-part': true,
                  'is-invalid': errors.length,
                  'is-active': _vm.selectedDoorIndex === index && _vm.selectedDoorPartIndex === (n-1) && _vm.selectedSurfaceType === 'door'
                },style:({
                  'backgroundImage': _vm.getFillingBg(_vm.data.doorsItems[index].fillings[n - 1].filling_id),
                  'opacity': _vm.getFillingOpacity(_vm.data.doorsItems[index].fillings[n - 1].filling_id)}),on:{"click":function($event){$event.preventDefault();return _vm.setSelectedDoorSection(index, n - 1)}}},[(!_vm.data.doorsItems[index].fillings[n - 1].filling_id)?_c('span',{staticClass:"wardrobe-dimensions wardrobe-door-width"},[_vm._v(" Vælg materiale ")]):_vm._e()])]}}],null,true)})}),1)}),_c('div',[_c('div',{staticClass:"wardrobe-side wardrobe-side-left wardrobe-core"}),(_vm.leftSideType === 'gable')?_c('validation-provider',{key:'door-left-side-validation-' + (_vm.data.gableFillingId || 0),staticClass:"door-side-validation",attrs:{"rules":"required","name":'gable-left'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{key:'door-left-side-' + (_vm.data.gableFillingId || 0),class:{
                  'wardrobe-side wardrobe-side-left is-gable': true,
                  'is-invalid': errors.length,
                  'is-active': _vm.selectedSurfaceType === 'gable'
                },style:({ 'backgroundImage': _vm.getFillingBg(_vm.data.gableFillingId, 'gable') }),on:{"click":function($event){$event.preventDefault();return _vm.setSelectedDoorSection(null, null, 'gable')}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.gableFillingId),expression:"data.gableFillingId"}],domProps:{"value":(_vm.data.gableFillingId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "gableFillingId", $event.target.value)}}})]}}],null,true)}):(_vm.leftSideType === 'impact')?_c('validation-provider',{key:'door-left-side-validation-' + (_vm.data.impactFillingId || 0),staticClass:"door-side-validation",attrs:{"rules":"required","name":'impact-left'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{key:'door-left-side-' + (_vm.data.impactFillingId || 0),class:{
                  'wardrobe-side wardrobe-side-left is-impact': true,
                  'is-invalid': errors.length,
                  'is-active': _vm.selectedSurfaceType === 'impact'
                },style:({ 'backgroundImage': _vm.getFillingBg(_vm.data.impactFillingId, 'impact') }),on:{"click":function($event){$event.preventDefault();return _vm.setSelectedDoorSection(null, null, 'impact')}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.impactFillingId),expression:"data.impactFillingId"}],domProps:{"value":(_vm.data.impactFillingId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "impactFillingId", $event.target.value)}}})]}}],null,true)}):_vm._e(),_c('div',{staticClass:"wardrobe-side wardrobe-side-right wardrobe-core"}),(_vm.rightSideType === 'gable')?_c('validation-provider',{key:'door-right-side-validation-' + (_vm.data.gableFillingId || 0),staticClass:"door-side-validation",attrs:{"rules":"required","name":'gable-right'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{key:'door-right-side-' + (_vm.data.gableFillingId || 0),class:{
                  'wardrobe-side wardrobe-side-right is-gable': true,
                  'is-invalid': errors.length,
                  'is-active': _vm.selectedSurfaceType === 'gable'
                },style:({ 'backgroundImage': _vm.getFillingBg(_vm.data.gableFillingId, 'gable') }),on:{"click":function($event){$event.preventDefault();return _vm.setSelectedDoorSection(null, null, 'gable')}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.gableFillingId),expression:"data.gableFillingId"}],domProps:{"value":(_vm.data.gableFillingId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "gableFillingId", $event.target.value)}}})]}}],null,true)}):(_vm.rightSideType === 'impact')?_c('validation-provider',{key:'door-right-side-validation-' + (_vm.data.impactFillingId || 0),staticClass:"door-side-validation",attrs:{"rules":"required","name":'impact-right'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{key:'door-right-side-' + (_vm.data.impactFillingId || 0),class:{
                  'wardrobe-side wardrobe-side-right is-impact': true,
                  'is-invalid': errors.length,
                  'is-active': _vm.selectedSurfaceType === 'impact'
                },style:({ 'backgroundImage': _vm.getFillingBg(_vm.data.impactFillingId, 'impact') }),on:{"click":function($event){$event.preventDefault();return _vm.setSelectedDoorSection(null, null, 'impact')}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.impactFillingId),expression:"data.impactFillingId"}],domProps:{"value":(_vm.data.impactFillingId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "impactFillingId", $event.target.value)}}})]}}],null,true)}):_vm._e()],1),(_vm.validationTriggered && invalid)?_c('div',{staticClass:"alert alert-danger alert-small"},[_vm._v(" Vælg venligst materiale til alle tilgængelige overflader. ")]):_vm._e()],2)]}}],null,false,1106493209)}):_vm._e()]},proxy:true}])}),_c('right-sidebar',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',[_vm._v("Skydelåger design")])]},proxy:true},{key:"content",fn:function(){return [(_vm.loaded)?_c('validation-observer',{ref:"filling-selection-form",staticClass:"pb-4",attrs:{"tag":"form","id":"doorsDivisionForm"}},[_c('div',{staticClass:"form-group flex-justify-content-center"},[_c('validation-provider',{attrs:{"name":"apply-to-all"}},[_c('div',[_c('c-checkbox',{on:{"change":_vm.applyToAll},model:{value:(_vm.data.isApplySameMaterial),callback:function ($$v) {_vm.$set(_vm.data, "isApplySameMaterial", $$v)},expression:"data.isApplySameMaterial"}},[_vm._v(" Tilføj på alle låger ")])],1)])],1),(_vm.wardrobeGableId)?_c('validation-provider',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSurfaceType === 'gable'),expression:"selectedSurfaceType === 'gable'"}],key:'door-filling-gabel' + (_vm.selectedSurfaceType === 'gable'),staticClass:"door-filling-options relative",attrs:{"rules":"required|min_value:1","name":"door-filling-gabel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return _vm._l((_vm.optionsGableFillings),function(filling,indexFilling){return _c('c-form-radio',{key:indexFilling,staticClass:"door-filling-wrapper mr-0 mb-0",attrs:{"withImage":true,"value":filling.id},on:{"change":function($event){return _vm.applyToAll(_vm.data.isApplySameMaterial, filling.id)}},model:{value:(_vm.data.gableFillingId),callback:function ($$v) {_vm.$set(_vm.data, "gableFillingId", $$v)},expression:"data.gableFillingId"}},[_c('div',{staticClass:"door-filling",style:({ 'backgroundImage': ("url(" + (filling.thumb) + ")")})}),_c('span',{staticClass:"c-form-radio-label door-filling-name"},[_vm._v(_vm._s(filling.name))])])})}}],null,false,1656363885)}):_vm._e(),(_vm.wardrobeImpact)?_c('validation-provider',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedSurfaceType === 'impact'),expression:"selectedSurfaceType === 'impact'"}],staticClass:"door-filling-options relative",attrs:{"rules":"required|min_value:1","name":"door-filling-impact"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return _vm._l((_vm.optionsImpactFillings),function(filling,indexFilling){return _c('c-form-radio',{key:indexFilling,staticClass:"door-filling-wrapper mr-0 mb-0",attrs:{"withImage":true,"value":filling.id},on:{"change":function($event){return _vm.applyToAll(_vm.data.isApplySameMaterial, filling.id)}},model:{value:(_vm.data.impactFillingId),callback:function ($$v) {_vm.$set(_vm.data, "impactFillingId", $$v)},expression:"data.impactFillingId"}},[_c('div',{staticClass:"door-filling",style:({ 'backgroundImage': ("url(" + (filling.thumb) + ")")})}),_c('span',{staticClass:"c-form-radio-label door-filling-name"},[_vm._v(_vm._s(filling.name))])])})}}],null,false,425478765)}):_vm._e(),(_vm.selectedSurfaceType === 'door')?_c('validation-provider',{staticClass:"door-filling-options relative",attrs:{"rules":"required|min_value:1","name":"door-filling-gable"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return _vm._l((_vm.optionsDoorFillings),function(filling,indexFilling){return _c('c-form-radio',{key:indexFilling,staticClass:"door-filling-wrapper mr-0 mb-0",attrs:{"withImage":true,"value":filling.id},on:{"change":function($event){return _vm.applyToAll(_vm.data.isApplySameMaterial, filling.id)}},model:{value:(_vm.data.doorsItems[_vm.selectedDoorIndex].fillings[_vm.selectedDoorPartIndex].filling_id),callback:function ($$v) {_vm.$set(_vm.data.doorsItems[_vm.selectedDoorIndex].fillings[_vm.selectedDoorPartIndex], "filling_id", $$v)},expression:"data.doorsItems[selectedDoorIndex].fillings[selectedDoorPartIndex].filling_id"}},[_c('div',{staticClass:"door-filling",style:({ 'backgroundImage': ("url(" + (filling.thumb) + ")")})}),_c('span',{staticClass:"c-form-radio-label door-filling-name"},[_vm._v(_vm._s(filling.name))])])})}}],null,false,337790476)}):_vm._e()],1):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('navigation-buttons',{attrs:{"form-id":'doorsDivisionForm'},on:{"nextBtnClicked":_vm.handleGoNext,"backBtnClicked":_vm.goBack}})]},proxy:true}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loader"},[_c('span',[_vm._v("Indlæsning af data…")])])}]

export { render, staticRenderFns }