import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    hasDoor: true,
    hasInventory: true,
    hasWardrobe: true,
    isSummaryPopupVisible: false,
    steps: {
      creatorType: false,
      wardrobeDimensions: false,
      wardrobePosition: false,
      wardrobeDoorsNumber: false,
      wardrobeDoorsDivision: false,
      wardrobeDoorsMaterial: false,
      wardrobeInventory: true
    }
  },
  mutations: {
    setIsSummaryPopupVisible (state, isSummaryPopupVisible) {
      Vue.set(state, 'isSummaryPopupVisible', isSummaryPopupVisible);
    },
    setStepCompletion (state, step) {
      Vue.set(state.steps, step.stepName, step.stepCompleted);
    },
    setHasDoorsOnly (state) {
      Vue.set(state, 'hasDoor', true);
      Vue.set(state, 'hasInventory', false);
      Vue.set(state, 'hasWardrobe', false);
    },
    setHasInventoryOnly (state) {
      Vue.set(state, 'hasDoor', false);
      Vue.set(state, 'hasInventory', true);
      Vue.set(state, 'hasWardrobe', false);
    },
    setHasFullWardrobe (state) {
      Vue.set(state, 'hasDoor', true);
      Vue.set(state, 'hasInventory', true);
      Vue.set(state, 'hasWardrobe', true);
    }
  },
  getters: {
    isSummaryPopupVisible: (state) => state.isSummaryPopupVisible,
    stepsState: (state) => state.steps,
    getIsStepCompleted: (state) => (stepName) => !!state.steps[stepName],
    hasDoorOnly: (state) => state.hasDoor && !state.hasInventory && !state.hasWardrobe,
    hasInventoryOnly: (state) => !state.hasDoor && state.hasInventory && !state.hasWardrobe,
    hasFullWardrobe: (state) => state.hasDoor && state.hasInventory && state.hasWardrobe,
    hasWardrobe: (state) => state.hasWardrobe,
    hasDoor: (state) => state.hasDoor,
    hasInventory: (state) => state.hasInventory
  }
};
