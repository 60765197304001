import Vue from 'vue';
import Vuex from 'vuex';
import apiData from './apiData.js';
import application from './application.js';
import creator from './creator.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    apiData,
    application,
    creator
  }
});
