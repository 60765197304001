<template>
  <div class="details-container">
    <details
      v-for="(category, categoryIndex) in optionsInventoryCategories"
      :key="categoryIndex"
      class="details">
      <summary class="details-item-title">{{ category.name }}</summary>
      <div class="details-item-content">
        <c-button
          v-for="(item, index) in optionsInventory.filter(option => option.category_id === category.id)"
          :disabled="!getIsItemInsertable(item)"
          :key="index"
          class="inventory-category"
          theme="tile"
          @click.prevent="addInventory(item.id)">

          <div class="img-wrapper">
            <img
              class="inventory-category-thumbnail"
              :src="item.thumb" />
          </div>
          <p class="inventory-category-name">
            {{ item.name }}
          </p>
          <p class="additional-info additional-info-price">
            <strong>Pris: {{ getPriceFormatted(item.price) }}</strong>
          </p>
          <p class="additional-info text-tiny">
            H:{{ item.height }} x B:{{ item.width === 'flex' ? `${item.min_width} - ${item.max_width}` :  item.width}} x D:{{ item.depth }}mm
          </p>
          <p
            v-if="!getIsItemInsertable(item)"
            class="inventory-message">
            Dette element er afhængigt af et andet, der endnu ikke er tilføjet.
          </p>
        </c-button>
      </div>
    </details>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CustomDecimals from '@/utils/CustomDecimals.js';

export default {
  name: 'inventory-list',
  props: {
    biggestSpace: {
      default: 0,
      type: Number
    },
    inventoryInWardrobe: {
      required: true,
      type: Array
    }
  },
  computed: {
    ...mapGetters('apiData', [
      'optionsInventoryCategories',
      'optionsInventory'
    ]),
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    addInventory (selectedItemID) {
      this.$emit('add-item-to-wardrobe', selectedItemID);
    },
    getIsItemInsertable (element) {
      if (element.category_id === 2) {
        return element.width <= this.biggestSpace;
      }

      if ((!element.fits_in || !element.fits_in.length) && !(element.width === 'flex')) {
        return true;
      }
      let existsItemsToFitIn = !!(element.fits_in && element.fits_in.length && (element.fits_in.some(item => this.inventoryInWardrobe.indexOf(item) > -1)));
      if (element.width === 'flex' && !existsItemsToFitIn) {
        let wallsOnScene = this.inventoryInWardrobe.filter(item => item === 2);
        return !!(wallsOnScene && wallsOnScene.length > 1);
      }

      return existsItemsToFitIn;
    },
    getPriceFormatted (price) {
      return CustomDecimals.getPriceFormatted(price);
    }
  }
}
</script>
