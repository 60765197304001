<template>
  <div class="view-content creator-view materials-selection">
    <div
      v-if="!loaded"
      class="loading-overlay">
      <div class="loader">
        <span>Indlæsning af data&hellip;</span>
      </div>
    </div>

    <wardrobe-preview>
      <template v-slot:content>
        <validation-observer
          v-if="loaded"
          class="wardrobe-layout with-materials mt-5 ml-auto mr-auto"
          :data-doors-count="doorsNumber"
          tag="div"
          ref="materials-form"
          v-slot="{ invalid }">
          <div
            :class="{
              'wardrobe-front':true,
              'is-invalid': invalid && validationTriggered
              }"
            v-bind:style="{ 'aspect-ratio': (wardrobeWidth / wardrobeHeight) }">
            <div
              v-for="(door, index) in doorsNumber"
              :key="'door-' + index"
              class="wardrobe-door">

              <validation-provider
                v-for="n in getDivisionsNumber(data.doorsItems[index].division_id)"
                :key="'door-' + index + '-part-' + n + '-' + (data.doorsItems[index].fillings[n - 1].filling_id || 0)"
                rules="required"
                :name="'door-' + index + '-part-' + n"
                v-slot="{ errors }"
                class="door-part-validation">
                <input
                  v-model="data.doorsItems[index].fillings[n - 1].filling_id"
                  :key="(data.doorsItems[index].fillings[n - 1].filling_id || 0)"/>
                <div
                  :key="'door-' + index + '-part-' + n + '-display'"
                  :class="{
                    'door-part': true,
                    'is-invalid': errors.length,
                    'is-active': selectedDoorIndex === index && selectedDoorPartIndex === (n-1) && selectedSurfaceType === 'door'
                  }"
                  @click.prevent="setSelectedDoorSection(index, n - 1)"
                  :style="{
                    'backgroundImage': getFillingBg(data.doorsItems[index].fillings[n - 1].filling_id),
                    'opacity': getFillingOpacity(data.doorsItems[index].fillings[n - 1].filling_id)}">
                    <span
                      v-if="!data.doorsItems[index].fillings[n - 1].filling_id"
                      class="wardrobe-dimensions wardrobe-door-width">
                      Vælg materiale
                    </span>
                </div>
              </validation-provider>
            </div>
            <div>
              <div class="wardrobe-side wardrobe-side-left wardrobe-core"></div>
              <validation-provider
                v-if="leftSideType === 'gable'"
                rules="required"
                :name="'gable-left'"
                v-slot="{ errors }"
                class="door-side-validation"
                :key="'door-left-side-validation-' + (data.gableFillingId || 0)">
                <div
                  :key="'door-left-side-' + (data.gableFillingId || 0)"
                  :class="{
                    'wardrobe-side wardrobe-side-left is-gable': true,
                    'is-invalid': errors.length,
                    'is-active': selectedSurfaceType === 'gable'
                  }"
                  @click.prevent="setSelectedDoorSection(null, null, 'gable')"
                  v-bind:style="{ 'backgroundImage': getFillingBg(data.gableFillingId, 'gable') }">
                </div>
                <input v-model="data.gableFillingId"/>
              </validation-provider>
              <validation-provider
                v-else-if="leftSideType === 'impact'"
                rules="required"
                :name="'impact-left'"
                v-slot="{ errors }"
                class="door-side-validation"
                :key="'door-left-side-validation-' + (data.impactFillingId || 0)">
                <div
                  :key="'door-left-side-' + (data.impactFillingId || 0)"
                  :class="{
                    'wardrobe-side wardrobe-side-left is-impact': true,
                    'is-invalid': errors.length,
                    'is-active': selectedSurfaceType === 'impact'
                  }"
                  @click.prevent="setSelectedDoorSection(null, null, 'impact')"
                  v-bind:style="{ 'backgroundImage': getFillingBg(data.impactFillingId, 'impact') }">
                </div>
                <input v-model="data.impactFillingId"/>
              </validation-provider>

              <div class="wardrobe-side wardrobe-side-right wardrobe-core"></div>

              <validation-provider
                v-if="rightSideType === 'gable'"
                rules="required"
                :name="'gable-right'"
                v-slot="{ errors }"
                class="door-side-validation"
                :key="'door-right-side-validation-' + (data.gableFillingId || 0)">
                <div
                  :key="'door-right-side-' + (data.gableFillingId || 0)"
                  :class="{
                    'wardrobe-side wardrobe-side-right is-gable': true,
                    'is-invalid': errors.length,
                    'is-active': selectedSurfaceType === 'gable'
                  }"
                  @click.prevent="setSelectedDoorSection(null, null, 'gable')"
                  v-bind:style="{ 'backgroundImage': getFillingBg(data.gableFillingId, 'gable') }">
                </div>
                <input v-model="data.gableFillingId"/>
              </validation-provider>
              <validation-provider
                v-else-if="rightSideType === 'impact'"
                rules="required"
                :name="'impact-right'"
                v-slot="{ errors }"
                class="door-side-validation"
                :key="'door-right-side-validation-' + (data.impactFillingId || 0)">
                <div
                  :key="'door-right-side-' + (data.impactFillingId || 0)"
                  :class="{
                    'wardrobe-side wardrobe-side-right is-impact': true,
                    'is-invalid': errors.length,
                    'is-active': selectedSurfaceType === 'impact'
                  }"
                  @click.prevent="setSelectedDoorSection(null, null, 'impact')"
                  v-bind:style="{ 'backgroundImage': getFillingBg(data.impactFillingId, 'impact') }">
                </div>
                <input v-model="data.impactFillingId"/>
              </validation-provider>
            </div>
            <div
              v-if="validationTriggered && invalid"
              class="alert alert-danger alert-small">
              Vælg venligst materiale til alle tilgængelige overflader.
            </div>
          </div>
        </validation-observer>
      </template>
    </wardrobe-preview>

    <right-sidebar>
      <template v-slot:header>
        <h2>Skydelåger design</h2>
      </template>

      <template v-slot:content>

        <validation-observer
          v-if="loaded"
          ref="filling-selection-form"
          tag="form"
          id="doorsDivisionForm"
          class="pb-4">
          <div class="form-group flex-justify-content-center">
            <validation-provider name="apply-to-all">
              <div>
                <c-checkbox
                  v-model="data.isApplySameMaterial"
                  @change="applyToAll">
                  Tilføj på alle låger
                </c-checkbox>
              </div>
            </validation-provider>
          </div>

          <validation-provider
            v-if="wardrobeGableId"
            v-show="selectedSurfaceType === 'gable'"
            rules="required|min_value:1"
            name="door-filling-gabel"
            class="door-filling-options relative"
            v-slot="{ errors }"
            :key="'door-filling-gabel' + (selectedSurfaceType === 'gable')">
            <c-form-radio
              v-for="(filling, indexFilling) in optionsGableFillings"
              :key="indexFilling"
              class="door-filling-wrapper mr-0 mb-0"
              :withImage="true"
              :value="filling.id"
              v-model="data.gableFillingId"
              @change="applyToAll(data.isApplySameMaterial, filling.id)">
              <div
                class="door-filling"
                :style="{ 'backgroundImage': `url(${filling.thumb})`}"></div>
              <span class="c-form-radio-label door-filling-name">{{ filling.name }}</span>
            </c-form-radio>
          </validation-provider>
          <validation-provider
            v-if="wardrobeImpact"
            v-show="selectedSurfaceType === 'impact'"
            rules="required|min_value:1"
            name="door-filling-impact"
            class="door-filling-options relative"
            v-slot="{ errors }">
            <c-form-radio
              v-for="(filling, indexFilling) in optionsImpactFillings"
              :key="indexFilling"
              class="door-filling-wrapper mr-0 mb-0"
              :withImage="true"
              :value="filling.id"
              v-model="data.impactFillingId"
              @change="applyToAll(data.isApplySameMaterial, filling.id)">
              <div
                class="door-filling"
                :style="{ 'backgroundImage': `url(${filling.thumb})`}"></div>
              <span class="c-form-radio-label door-filling-name">{{ filling.name }}</span>
            </c-form-radio>
          </validation-provider>
          <validation-provider
            v-if="selectedSurfaceType === 'door'"
            rules="required|min_value:1"
            name="door-filling-gable"
            class="door-filling-options relative"
            v-slot="{ errors }">
            <c-form-radio
              v-for="(filling, indexFilling) in optionsDoorFillings"
              :key="indexFilling"
              class="door-filling-wrapper mr-0 mb-0"
              :withImage="true"
              :value="filling.id"
              v-model="data.doorsItems[selectedDoorIndex].fillings[selectedDoorPartIndex].filling_id"
              @change="applyToAll(data.isApplySameMaterial, filling.id)">
              <div
                class="door-filling"
                :style="{ 'backgroundImage': `url(${filling.thumb})`}"></div>
              <span class="c-form-radio-label door-filling-name">{{ filling.name }}</span>
            </c-form-radio>
          </validation-provider>
        </validation-observer>
      </template>

      <template v-slot:footer>
        <navigation-buttons
          :form-id="'doorsDivisionForm'"
          @nextBtnClicked="handleGoNext"
          @backBtnClicked="goBack" />
      </template>
    </right-sidebar>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import FormUtils from '@/utils/FormUtils.js';
import html2canvas from 'html2canvas';

export default {
  name: 'wardrobe-doors-material',
  computed: {
    ...mapGetters('creator', [
      'wardrobeHeight',
      'wardrobeWidth',
      'wardrobePositionId',
      'doorsNumber',
      'wardrobeGableId',
      'wardrobeImpact',
      'wardrobeGableFillingId',
      'wardrobeImpactFillingId'
    ]),
    ...mapGetters('apiData', [
      'optionsWardrobePositions',
      'optionsGable',
      'optionsDoorFillings',
      'optionsGableFillings',
      'optionsImpactFillings'
    ]),
    availableOptionsDoorDivisions () {
      return this.$store.getters['apiData/getAvailableOptionsDoorDivisions'](this.wardrobeHeight)
    },
    wardrobePositionObject () {
      return this.optionsWardrobePositions.find(item => item.id === this.wardrobePositionId);
    },
    leftSideType () {
      if (!this.wardrobePositionObject) {
        return '';
      }

      if (this.wardrobeImpact && this.wardrobePositionObject.impact_position && this.wardrobePositionObject.impact_position.indexOf('left') > -1) {
        return 'impact';
      }

      if (this.wardrobeGableId && this.wardrobePositionObject.gable_position && this.wardrobePositionObject.gable_position.indexOf('left') > -1) {
        return 'gable';
      }

      return '';
    },
    rightSideType () {
      if (!this.wardrobePositionObject) {
        return '';
      }

      if (this.wardrobeImpact && this.wardrobePositionObject.impact_position && this.wardrobePositionObject.impact_position.indexOf('right') > -1) {
        return 'impact';
      }

      if (this.wardrobeGableId && this.wardrobePositionObject.gable_position && this.wardrobePositionObject.gable_position.indexOf('right') > -1) {
        return 'gable';
      }

      return '';
    },
    fillingsForSelectedSurface () {
      if (this.selectedSurfaceType === 'gable') {
        return this.optionsGableFillings;
      }

      if (this.selectedSurfaceType === 'impact') {
        return this.optionsImpactFillings;
      }

      return this.optionsDoorFillings;
    }
  },
  watch: {
    'data.isApplySameMaterial': function(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.shouldShowWarning = true;
      }
    }
  },
  data () {
    return {
      data: {
        doorsItems: [],
        gableFillingId: null,
        impactFillingId: null,
        isApplySameMaterial: false
      },
      loaded: false,
      selectedDoorIndex: 0,
      selectedDoorPartIndex: 0,
      selectedSurfaceType: 'door',
      shouldShowWarning: false,
      validationTriggered: false,
    }
  },
  mounted () {
    Vue.nextTick(() => {
      this.data.isApplySameMaterial = this.$store.getters['creator/isApplySameMaterial'];

      this.data.doorsItems = JSON.parse(JSON.stringify(this.$store.getters['creator/doorsItems']));

      this.data.gableFillingId = this.wardrobeGableFillingId;
      this.data.impactFillingId = this.wardrobeImpactFillingId;

      // Check if values still exist in case available fillings have changed
      if (this.wardrobeGableId && this.wardrobeGableFillingId) {
        let isGableFillingAvailable = this.optionsGableFillings.find(item => item.id === this.wardrobeGableFillingId);

        if (!isGableFillingAvailable) {
          this.data.gableFillingId = null;
        }
      }

      if (this.wardrobeImpact && this.wardrobeImpactFillingId) {
        let isImpactFillingAvailable = this.optionsImpactFillings.find(item => item.id === this.wardrobeImpactFillingId);

        if (!isImpactFillingAvailable) {
          this.data.impactFillingId = null;
        }
      }

      this.data.doorsItems.forEach(item => {
        item.fillings.forEach(filling => {
          let isFillingAvailable = this.optionsDoorFillings.find(option => option.id === filling.filling_id);
          if (!isFillingAvailable) {
            filling.filling_id = null;
          }
        });
      });

      this.loaded = true;
    })
  },
  methods: {
    setSelectedDoorSection (doorIndex, doorPartIndex, surfaceType = 'door') {
      if (doorIndex === null && doorPartIndex === null && !surfaceType.length) {
        return;
      }

      Vue.set(this, 'selectedDoorIndex', doorIndex);
      Vue.set(this, 'selectedDoorPartIndex', doorPartIndex);
      Vue.set(this, 'selectedSurfaceType', surfaceType);
    },
    validateForm () {
      this.validationTriggered = true;
      FormUtils.validate(this.$refs['filling-selection-form'], this.goNextStep);
    },
    saveState (shouldMarkStepCompleted = false) {
      this.$store.commit('creator/setDoorsItems', this.data.doorsItems);
      this.$store.commit('creator/setWardrobeGableFillingId', this.data.gableFillingId);
      this.$store.commit('creator/setWardrobeImpactFillingId', this.data.impactFillingId);
      this.$store.commit('creator/setIsApplySameMaterial', this.data.isApplySameMaterial);

      if (shouldMarkStepCompleted) {
        this.$store.commit('application/setStepCompletion', { stepName: 'wardrobeDoorsMaterial', stepCompleted: true });
      }
    },
    async goNextStep () {
      this.saveState(true);
      await this.saveCanvasImg();

      Vue.nextTick(() => {
        if (this.$store.getters['application/hasDoorOnly']) {
          if (this.$route.query && this.$route.query.orderSummary) {
            this.$router.push({ name: 'placeOrder' });
          } else {
            this.$router.replace({ query: { orderSummary: true } });
          }

          return;
        }
        this.$router.push({ name: 'wardrobeInventory' });
      });
    },
    handleGoNext () {
      if (this.$route.query && this.$route.query.orderSummary) {
        this.goNextStep();
        return;
      }

      this.validateForm();
    },
    goBack () {
      this.saveState();

      Vue.nextTick(() => {
        if (this.$route.query && this.$route.query.orderSummary) {
          this.$router.replace({ query: null });
          return;
        }
        this.$router.push({ name: 'wardrobeDoorsDivision' });
      });
    },
    updatePrice () {
      this.$bus.$emit('update-wardrobe-price');
    },
    getDivisionsNumber (divisionID) {
      if (!divisionID) {
        return 1;
      }

      let divisionFound = this.availableOptionsDoorDivisions.find(item => item.id === divisionID);

      if (divisionFound && divisionFound.divide) {
        return divisionFound.divide;
      }
      return 1;
    },
    getFillingBg (fillingID, surfaceType = 'door') {
      if (!fillingID) {
        return '';
      }

      let fillingFound = null;
      if (surfaceType === 'door') {
        fillingFound = this.optionsDoorFillings.find(item => item.id === fillingID);
      } else if (surfaceType === 'gable') {
        fillingFound = this.optionsGableFillings.find(item => item.id === fillingID);
      } else if (surfaceType === 'impact') {
        fillingFound = this.optionsImpactFillings.find(item => item.id === fillingID);
      } else {
        console.error('invalid surface type ', surfaceType);
        return '';
      }

      if (fillingFound && fillingFound.background) {
        return `url(${fillingFound.background})`;
      }
      return '';
    },
    getFillingOpacity (fillingID, surfaceType = 'door') {
      if (!fillingID) {
        return 1;
      }

      let fillingFound = null;
      if (surfaceType === 'door') {
        fillingFound = this.optionsDoorFillings.find(item => item.id === fillingID);
      } else if (surfaceType === 'gable') {
        fillingFound = this.optionsGableFillings.find(item => item.id === fillingID);
      } else if (surfaceType === 'impact') {
        fillingFound = this.optionsImpactFillings.find(item => item.id === fillingID);
      } else {
        console.error('invalid surface type ', surfaceType);
        return 1;
      }

      if (fillingFound && fillingFound.is_mirror) {
        return '0.95';
      }
      return 1;
    },
    applyToAll (shouldApply, fillingID = null) {
      if (!shouldApply) {
        if (this.shouldShowWarning) {
          Vue.swal({
            title: 'Opmærksomhed',
            text: 'Denne ændring vil kun påvirke den valgte del af garderoben.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'OK',
            showCloseButton: true,
          })
        }
        return;
      }

      let selectedFillingID = fillingID;
      if (!selectedFillingID) {
        if (this.selectedSurfaceType === 'impact') {
          selectedFillingID = this.data.impactFillingId;
        } else if (this.selectedSurfaceType === 'gable') {
          selectedFillingID = this.data.gableFillingId;
        } else {
          selectedFillingID = this.data.doorsItems[this.selectedDoorIndex].fillings[this.selectedDoorPartIndex].filling_id;
        }
      }

      // fill all doors
      let selectedDoorFillingFound = this.optionsDoorFillings.find(item => item.id === selectedFillingID);
      if (selectedDoorFillingFound) {
        for (let i = 0; i < this.data.doorsItems.length; i++) {
          let doorItem = this.data.doorsItems[i];
          for (let j = 0; j < doorItem.fillings.length; j++) {
            doorItem.fillings[j].filling_id = selectedFillingID;
          }
        }
      }

      // fill gable
      if (this.wardrobeGableId) {
        let selectedGableFillingFound = this.optionsGableFillings.find(item => item.id === selectedFillingID);
        if (selectedGableFillingFound) {
          this.data.gableFillingId = selectedFillingID;
        }
      }

      // fill impact
      if (this.wardrobeImpact) {
        let selectedImpactFillingFound = this.optionsImpactFillings.find(item => item.id === selectedFillingID);
        if (selectedImpactFillingFound) {
          this.data.impactFillingId = selectedFillingID;
        }
      }
    },
    async saveCanvasImg () {
      try {
        let materialsContent = this.$refs['materials-form'];
        let materialsContentCanvas = await html2canvas(materialsContent.$el);
        window.pandoraCanvasScreenshotMaterials = materialsContentCanvas.toDataURL();
      } catch (error) {
        console.error('Exporting image failed:', error);
      }
    },
  }
};
</script>
