var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-content creator-view"},[(!_vm.loaded)?_c('div',{staticClass:"loading-overlay"},[_vm._m(0)]):_vm._e(),_c('wardrobe-preview',{scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.loaded)?_c('div',{staticClass:"wardrobe-layout mt-5 ml-auto mr-auto",attrs:{"data-doors-count":_vm.doorsNumber}},[_c('div',{staticClass:"wardrobe-front",style:({ 'aspect-ratio': (_vm.wardrobeWidth / _vm.wardrobeHeight) })},_vm._l((_vm.doorsNumber),function(door,index){return _c('div',{key:'door-' + index + '-',class:{
              'wardrobe-door': true,
              'is-active': _vm.selectedDoorIndex === index
            },attrs:{"active":_vm.selectedDoorIndex === index},on:{"click":function($event){$event.preventDefault();return _vm.setSelectedDoorSection(index)}}},[_vm._l((_vm.getDivisionsNumber(_vm.data.doorsItems[index].division_id)),function(n){return _c('div',{key:'door-' + index + '-part-' + n + '-display',class:{
                'door-part': true,
                'is-active': _vm.selectedDoorIndex === index
              }})}),_c('span',{staticClass:"wardrobe-dimensions wardrobe-door-width"},[_vm._v("Vælg døre-deling")])],2)}),0)]):_vm._e()]},proxy:true}])}),_c('right-sidebar',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',[_vm._v("Skydelåger design")])]},proxy:true},{key:"content",fn:function(){return [(_vm.loaded)?_c('validation-observer',{ref:"divisions-selection-form",attrs:{"tag":"form","id":"doorsDivisionForm"}},[_c('validation-provider',{key:'division-for-door-' + _vm.selectedDoorIndex,staticClass:"grid-cols-1",attrs:{"rules":"required","name":"door-divisions-options"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_vm._l((_vm.availableOptionsDoorDivisions),function(option,optionIndex){return _c('c-form-radio',{key:optionIndex,staticClass:"door-division-wrapper ml-auto mr-auto",attrs:{"withImage":true,"value":option.id,"disabled":option.disabled},on:{"change":_vm.updatePrice},model:{value:(_vm.data.doorsItems[_vm.selectedDoorIndex].division_id),callback:function ($$v) {_vm.$set(_vm.data.doorsItems[_vm.selectedDoorIndex], "division_id", $$v)},expression:"data.doorsItems[selectedDoorIndex].division_id"}},[_c('img',{staticClass:"door-count-img",style:({'height': '100%'}),attrs:{"src":require('@/assets/images/svg/divide' + option.divide + '.svg'),"alt":'Dør-deling - ' + option.divide}}),_c('span',{staticClass:"c-form-radio-label door-divisions-name"},[_vm._v(_vm._s(option.name))])])}),_c('div',{staticClass:"invalid-feedback absolute"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,1861709196)})],1):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('navigation-buttons',{attrs:{"form-id":'doorsDivisionForm'},on:{"nextBtnClicked":_vm.validateForm,"backBtnClicked":_vm.goBack}})]},proxy:true}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loader"},[_c('span',[_vm._v("Indlæsning af data…")])])}]

export { render, staticRenderFns }