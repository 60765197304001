import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    doorDivisions: [],
    doorFillings: [],
    gableFillings: [],
    gableOptions: [],
    impactFillings: [],
    inventory: [],
    inventoryCategories: [],
    wardrobePositions: [],
    inventoryPresets: [
      {
        price: 2370,
        name: '140 - 1',
        width: 1400,
        thumb: '/src/assets/images/preset-140-1.png',
        inventory: [
          {
            inventoryItemId: 8,
            id: 'item-vertical-Gavle-387-2',
            isReversed: false,
            isNotAttached: false,
            attachedItems: [
              {
                inventoryItemId: 3,
                isReversed: false,
                isNotAttached: false,
                posY: 290
              },
              {
                inventoryItemId: 3,
                isReversed: false,
                isNotAttached: false,
                posY: 640
              },
              {
                inventoryItemId: 3,
                isReversed: false,
                isNotAttached: false,
                posY: 990
              },
              {
                inventoryItemId: 3,
                isReversed: false,
                isNotAttached: false,
                posY: 1340
              },
              {
                inventoryItemId: 3,
                isReversed: false,
                isNotAttached: false,
                posY: 1690
              }
            ]
          }, {
            inventoryItemId: 10,
            id: 'item-vertical-Gavle-587-1',
            isReversed: false,
            isNotAttached: false,
            attachedItems: [
              {
                inventoryItemId: 6,
                isReversed: false,
                isNotAttached: false,
                posY: 400
              },
              {
                inventoryItemId: 6,
                isReversed: false,
                isNotAttached: false,
                posY: 750
              },
              {
                inventoryItemId: 33,
                isReversed: false,
                isNotAttached: false,
                posY: 1950,
                width: 568
              },
            ]
          }, {
            inventoryItemId: 8,
            id: 'item-vertical-Gavle-387-1',
            isReversed: true,
            isNotAttached: false,
            attachedItems: [
              {
                inventoryItemId: 3,
                isReversed: false,
                isNotAttached: false,
                posY: 290
              },
              {
                inventoryItemId: 3,
                isReversed: false,
                isNotAttached: false,
                posY: 640
              },
              {
                inventoryItemId: 3,
                isReversed: false,
                isNotAttached: false,
                posY: 990
              },
              {
                inventoryItemId: 3,
                isReversed: false,
                isNotAttached: false,
                posY: 1340
              },
              {
                inventoryItemId: 3,
                isReversed: false,
                isNotAttached: false,
                posY: 1690
              }
            ]
          }, {
            category_id: 4,
            inventoryItemId: 2,
            id: 'item-vertical-Indvendig-Gavl-19-1',
            isReversed: false,
            isNotAttached: false,
            attachedItems: [
              // 'item-vertical-Gavle-387-1',
              // 'item-vertical-Gavle-587-1'
            ]
          },
          //  {
          //   inventoryItemId: 3,
          //   id: 'item-horizontal-Hylder-368-1',
          //   isReversed: false,
          //   isNotAttached: false,

          // }, {
          //   inventoryItemId: 6,
          //   id: 'item-horizontal-Hylder-568-1',
          //   isReversed: false,
          //   isNotAttached: false,
          // },
          //  {
          //   inventoryItemId: 3,
          //   id: 'item-horizontal-Hylder-368-8',
          //   isReversed: false,
          //   isNotAttached: false
          // },
        ]
      }
    ]
  },
  mutations: {
    setApiData (state, dataFromAPI) {
      Vue.set(state, 'doorDivisions', dataFromAPI.door_divisions);
      Vue.set(state, 'doorFillings', dataFromAPI.door_fillings);
      Vue.set(state, 'gableFillings', dataFromAPI.gable_fillings);
      Vue.set(state, 'gableOptions', dataFromAPI.gable_options);
      Vue.set(state, 'impactFillings', dataFromAPI.impact_fillings);
      Vue.set(state, 'inventory', dataFromAPI.inventory);
      Vue.set(state, 'inventoryCategories', dataFromAPI.inventory_categories);
      Vue.set(state, 'wardrobePositions', dataFromAPI.wardrobe_positions);
    }
  },
  getters: {
    optionsDoorDivisions: (state) => state.doorDivisions,
    getAvailableOptionsDoorDivisions: (state) => (wardrobeHeight) => state.doorDivisions.filter(item => item.max_height >= wardrobeHeight),
    optionsDoorFillings: (state) => state.doorFillings,
    optionsGableFillings: (state) => state.gableFillings,
    optionsGable: (state) => state.gableOptions,
    optionsImpactFillings: (state) => state.impactFillings,
    optionsInventory: (state) => state.inventory,
    optionsInventoryCategories: (state) => state.inventoryCategories,
    optionsWardrobePositions: (state) => state.wardrobePositions,
    optionsInventoryPresets: (state) => state.inventoryPresets,
  }
};
