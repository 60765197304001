<template>
  <div class="navigation-buttons flex mt-auto">
    <c-button
      :theme="'secondary'"
      class=""
      @click.prevent="onGoBack">
      Tilbage
    </c-button>

    <c-button
      :form="formId"
      class=""
      :type="formId? 'submit' : 'button'"
      @click.prevent="onGoNext">
      <template v-if="isSummaryView">
        Bestil og betal
      </template>
      <template v-else>
        Næste
      </template>
    </c-button>
  </div>
</template>

<script>
export default {
  name: 'navigation-buttons',
  props: {
    formId: {
      type: String,
      default: ''
    }
  },
  methods: {
    onGoNext () {
      this.$emit('nextBtnClicked');
    },
    onGoBack () {
      this.$emit('backBtnClicked');
    },
  },
  computed: {
    isSummaryView () {
      if (this.$route.query && this.$route.query.orderSummary) {
        return true;
      }

      return false;
    }
  }
}
</script>
